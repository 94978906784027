import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils } from '@fortawesome/free-solid-svg-icons'
import { Dispatch } from '@Corim/architecture-frontend'

export default (props) => {
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  const primaryColor = shop.primaryColor
  const secondaryColor = shop.secondaryColor
  const total = (shop?.order?.pricing?.subTotal/100).toFixed(2)
  const count = shop?.order?.line_items?.reduce((acc, next) => { return parseInt(next.quantity) + acc }, 0)
  return(
    <div className='fixed w-100 dn-ns' style={{bottom: 10, zIndex: 10000}}>
      <div className='flex w-100 avenir items-center justify-center' onClick={e => Dispatch.nextAction('__.Modal.open', { type: 'checkout' })} >
        {count > 0 ?
          
            <div className={'w-90 pv2 br2 shadow-1 bg-' + primaryColor }>
              <div className={'w-100 mw6 flex justify-around items-center f4 fw4 ' + secondaryColor }>
                <div className='w-30 pv2 ph3 '>{count}</div>
                <div className='w-40 pv2 fw6 tc'>Your Order</div>
                <div className='w-30 pv2 ph3 tr'>${total}</div>
              </div>
            </div>
        :
        null
        }
      </div>
    </div>
  )
}