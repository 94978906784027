import React from 'react';
import { faUtensils } from '@fortawesome/free-solid-svg-icons'
import { Dispatch, Textbox, Select, SubmitButton, MultiSelect } from '@Corim/architecture-frontend'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faParachuteBox, faArrowAltCircleLeft, faBars, faFish, faPlus} from '@fortawesome/free-solid-svg-icons'
import Payment from './Payment'
import _ from 'lodash'
import { tap, map, startWith, mergeMap, switchMap, filter, delay } from 'rxjs/operators'
import { merge, combineLatest, of } from 'rxjs'
import loading from '../../media/loading.svg'
import ModalNavigator from './ModalNavigator';
import moment from 'moment'


const incrementalValues = [
  {value:1, label:'1'},
  {value:2, label:'2'},
  {value:3, label:'3'},
  {value:4, label:'4'},
  {value:5, label:'5'},
  // {value:6, label:'6'},
  // {value:7, label:'7'},
  // {value:8, label:'8'},
  // {value:9, label:'9'},
  // {value:10, label:'10'},
]

const Modifier = (props) => {
  const modifier = props.z_modifier
  const primaryColor = props.__?.Shop?.primaryColor
  return (
    <div key='modifier.uid' className='w-100 f5 fw4 flex justify-between'>
      <div  >{modifier.name}</div>
      <div key='' className='f7 fw7 red'>+${(modifier.basePriceMoney.amount/100).toFixed(2)}</div>
    </div>
  )
}

const Item = (props) => {
  const item = props.__item
  const primaryColor = props._primaryColor
  const secondaryColor = props._secondaryColor
  const variationName = item.variation_name
  const modifiers = item.modifiers ? item.modifiers : []
  const itemAvailability = item.booking

  const pickupTime = itemAvailability && moment(item.booking?.endTime)
  const orderByTime = itemAvailability && moment(itemAvailability.orderBy)
  const available = itemAvailability && ('Earliest Pickup: ' + pickupTime.format('dddd Do h:mm a'))
  const remainingTime = itemAvailability && (orderByTime.fromNow() !== 'a few seconds ago') && ('Order within ' + orderByTime.fromNow().replace('in ', ''))
  const displayRemainingTime = props.__?.Shop?.displayRemainingTime ?? false
  const displayEarliestAvailability = props.__?.Shop?.displayEarliestAvailability ?? false
  return (
    <div className='pb4 w-100 flex flex-column'>
      <div className='w-100 flex'>
        <div className='w-100'>
          <div className='w-100 flex justify-between items-center'>
            <div>
              <div className={'f4 fw8 ' + primaryColor}>{item.name}</div>
              {variationName ? <div className='pt1 f5 fw4'>{variationName}</div> : null }
            </div>
            <div className='f5 fw7 red'>${(item?.basePriceMoney?.amount/100).toFixed(2)}</div>
          </div>
          <div className='pt2'>{modifiers.map(mod => <Modifier key={mod.uid} {...props} z_modifier={mod}/>)}</div>
          {item.note ? <div className='pt3 f6 fw5'> <span className='f6 fw8'>Note:</span> {item.note}</div> : null }
          {available && displayEarliestAvailability ? <div className='pt1 pb1 f7 fw7'>{available}</div> : null}
            {remainingTime && displayRemainingTime ? <div className=' f7 fw7 red'>{remainingTime}</div> : null}
        </div>
        
      </div>
      <div className='pt1 w-100 flex justify-between'>
        <div></div>
        <div className=''>
          <Select className='w4 dark-gray'
            id={'cart.quantities.' + item.uid}
            options={incrementalValues}
            defaultValue={incrementalValues[item.quantity - 1]}
            >
          </Select>
            <SubmitButton id={'removeitem'} className='flex pt2 justify-between' 
              defaultChild={ props => (
                <>
                <div></div>
                <div className='f7 blue pointer ' onClick={e => Dispatch.nextAction('order.removeItem', item.uid)}>remove</div>
                </>
              )}
              loadingChild={ props => (
                <div className='w-100 flex justify-between'>
                  <div></div>
                  <div className='w2'>
                    <img src={loading} />
                  </div>
                </div>
              )}
              errorChild={ props => (
                <div className='pl3 f7 fw4 red tr pointer'>Error</div>
              )}
              loadingStream={
                Dispatch.getAction('Request.removeItem')
                }
              successStream={
                Dispatch.getAction('Response.removeItem')
                  .pipe(
                    filter(e => _.isNil(e.errors) && (!_.isNil(e.data.removeItem))),
                  )
                }
              errorStream={Dispatch.getAction('Response.removeItem')
              .pipe(
                filter(e => !_.isNil(e.errors) || (_.isNil(e.data.removeItem))),
              )}
            />
        </div>
      </div>
    </div>
  )
}

export default (props) => {
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  const primaryColor = shop.primaryColor
  const secondaryColor = shop.secondaryColor
  const isClosed = shop.isClosed === 'true'
  const pricing = shop.order?.pricing ?? {}

  const total = pricing.total
  const totalDiscount = pricing.totalDiscount
  const totalTax = pricing.totalTax
  const subtotal = pricing.subTotal
  const tip = pricing.tip
  const tipPercentage = shop.tip?.percentage
  
  const lineItems = shop?.order?.line_items ?? []
  const count = lineItems && lineItems.reduce((acc, next) => { return parseInt(next.quantity) + acc }, 0)

  const options = [0,5,10,20].map(option => {
    const TipMultiButton = (props) => {
      const selected = props.selected
      const selectedClass = selected ? 'bg-' + primaryColor + ' ' + secondaryColor : ''
      return (
            <div className={'mv1 ml1 pa3 f5 br2 b--moon-gray ba pointer ' + selectedClass }>
              {option}%
            </div>
      )
    }
    return {id: option, component: TipMultiButton}
  })

  return(
    <div className='w-100 bg-white ' style={{bottom: 10, zIndex: 10000}}>
        <ModalNavigator {...props} _title='Shopping Cart' _action={e => Dispatch.nextAction('__.Modal.close')} />
        {count > 0 ?
        <div className='ph4 pb4 pt3'>
          <div className=''>{lineItems.map(item => <Item key={item.uid} {...props} __item={item} />)}</div>
          <div className='pt4 bt b--near-white'></div>
          <div className='f7 fw7'>Coupon Code</div>
          <div className='pt1 flex'>
            <Textbox
                  className='w-100 pv3 ph3 ba br2 dark-gray b--moon-gray fw4 '
                  id='cart.coupon'
                  placeholder=''
                  style={{ outline: 'none', borderStyle: 'solid' }}
                  type='text'
                  defaultValue={''}
            />
            <SubmitButton id={'addCoupon'} className='flex items-center pointer' 
                defaultChild={ props => (
                  <div className={'ml2 ph3 pv3 br2 fw6 bg-' + primaryColor + ' ' + secondaryColor} onClick={e => Dispatch.nextAction('order.addCoupon')}>Update</div>
                )}
                loadingChild={ props => (
                  <div className='w-100 ph4 flex justify-between'>
                    <div></div>
                    <div className='w2'>
                      <img src={loading} />
                    </div>
                  </div>
                )}
                errorChild={ props => (
                  <div className='pl3 f7 fw4 red tr pointer'>Error</div>
                )}
                loadingStream={
                  Dispatch.getAction('Request.addCoupon')
                  }
                successStream={
                  Dispatch.getAction('Response.addCoupon')
                    .pipe(
                      filter(e => _.isNil(e.errors) && (!_.isNil(e.data.addCoupon))),
                    )
                  }
                errorStream={
                  Dispatch.getAction('Response.addCoupon')
                    .pipe(
                      filter(e => !_.isNil(e.errors) || (_.isNil(e.data.addCoupon))),
                    )}
              />
          </div>
          <div className='mt4 bt b--near-white'></div>
          <div className='pt4 flex flex-column'>
            <div className='pt1 w-100 tr f6'> In-Store Pickup: <span className='fw7 red'> $0.00 </span> </div>
            {totalDiscount !== 0 ? 
              <div className='pt1 w-100 tr f6'> Discount: <span className='fw7 red'> ${(totalDiscount/100).toFixed(2)} </span> </div>
              :
              null
            }
            <div className='pt1 w-100 tr f6'> Subtotal ({count} items): <span className='fw7 red'>${(subtotal/100).toFixed(2)}</span></div>
            {/* <div className='w-100 pt3 tr f6'> Tip: <span className='fw8 red'> ${(details.total_service_charge_money.amount/100).toFixed(2)} </span> </div> */}
            { !_.isNil(tip) && tipPercentage &&
              <>
                <div className='pt3'></div>
                <div className='w-100 tr f6'> {tipPercentage}% Tip <span className='blue pointer' onClick={e => Dispatch.nextAction('__.Shop.changeTipPercentage')}>(change)</span>: <span className='fw7 red'> ${(tip/100).toFixed(2)} </span> </div>
              </>
            }
            { shop?.tip?.open &&
              <div className='pt2 pb2 w-100 flex justify-between'>
                <div></div>
                <div>
                <MultiSelect options={options} id={'tip'} isMultiSelect={false} defaultValue={{[tipPercentage]: true}}/>
                </div>
              </div>
            }
            <div className='pt1 w-100 tr f6'> Sales Tax: <span className='fw7 red'> ${(totalTax/100).toFixed(2)} </span> </div>
            <div className='w-100 pt3 tr f5 fw7'> Total: <span className=' red'> ${(total/100).toFixed(2)} </span> </div>
          </div>
          <div className='pt4 pb4 flex justify-center items-center f5 fw5'>
            {isClosed ? 
              <div 
                className={'mt2 ph5 pv3 br2 f4 fw5 pointer bg-gray white'} 
                >
                  Currently Closed
              </div>
              :
              // <div 
              //   className={'mt2 ph5 pv3 br2 f4 fw5 pointer bg-' + primaryColor + ' ' + secondaryColor} 
              //   onClick={e => Dispatch.nextAction('__.Modal.open', {type: 'payment'})}>
              //     Checkout
              // </div>
              <div 
                className={'mt2 ph5 pv3 br2 f4 fw5 pointer bg-' + primaryColor + ' ' + secondaryColor} 
                onClick={e => Dispatch.nextAction('__.Modal.open', {type: 'fullfillment'})}>
                  Checkout
              </div>
            }
          </div>
          <div className='h3 h1-ns'></div>
        </div>
        :
        <div className='pa4 w-100 pt3 flex items-center justify-center'>
          <div className='f4-ns fw7'>Your cart is empty. Go get some items!</div>
        </div>
      }
    </div>
  )
}