import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils } from '@fortawesome/free-solid-svg-icons'
import { Dispatch, MultiSelect, SubmitButton } from '@Corim/architecture-frontend'
import ModalNavigator from './ModalNavigator';
import moment from 'moment'
import loading from '../../media/loading.svg'
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import { of, merge, combineLatest } from 'rxjs'
import { map, filter, withLatestFrom, tap, mergeMap, startWith } from 'rxjs/operators'

export default (props) => {
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  const primaryColor = shop.primaryColor
  const secondaryColor = shop.secondaryColor
  const method = shop?.order?.fullfillment?.method
  const methodKey = shop?.order?.fullfillment?.method && _.findKey(shop?.order?.fullfillment?.method, entry => entry === true)
  //const schedule = props.MultiSelect && props.MultiSelect.cart && props.MultiSelect.cart[method] && props.MultiSelect.cart[method].schedule && _.findKey(props.MultiSelect.cart[method].schedule, entry => entry === true)
  const schedule = shop?.order?.fullfillment?.schedule //&& _.findKey(props.MultiSelect.cart.schedule, entry => entry === true)
  const scheduleKey = shop?.order?.fullfillment?.schedule && _.findKey(shop?.order?.fullfillment?.schedule, entry => entry === true)
  const methods = shop.fullfillmentMethods.map(option => {
    const MultiButton = (props) => {
      const selected = props.selected
      const selectedClass = selected ? 'bg-' + primaryColor + ' ' + secondaryColor : ''
      return (
        <div id={option.method} className={'mv1 mr1 pa3 f5 br2 b--moon-gray ba pointer ' + selectedClass }>
          {option.method}
        </div>
      )
    }
    return {...option, component: MultiButton}
  })
  const methodObject = shop.fullfillmentMethods && _.find(shop.fullfillmentMethods, item => item.id === parseInt(methodKey) )
  const schedules = methodObject ? methodObject.schedules.map(option => {
      const MultiButton = (props) => {
        const selected = props.selected
        const selectedClass = selected ? 'bg-' + primaryColor + ' ' + secondaryColor : ''
        return (
          <div id={option.type} className={'mv1 mr1 pa3 f5 br2 b--moon-gray ba pointer ' + selectedClass }>
            {option.type}
          </div>
        )
      }
      return {...option, component: MultiButton}
  })
  :
  []
  
  return(
    <div className='w-100'>
      <ModalNavigator {...props} _title='Fullfillment' _action={e => Dispatch.nextAction('__.Modal.open', { type: 'checkout' })} />
      <div className='pa4'>
        <div className='pb1 f5 fw8'>1) Method: </div>
        <MultiSelect options={methods} id={'cart.method'} isMultiSelect={false} defaultValue={method}/>
        <div className='pb1 pt4 f5 fw8'>2) Schedule: </div>
        {method && <MultiSelect key={method} options={schedules} id={'cart.schedule' } isMultiSelect={false} defaultValue={schedule}/>}
        <div className='pt4 f5 fw8'>3) { scheduleKey === '3' ? 'Check Availability:' : 'Ready At:' } </div>
        <div className='w-100 pt2 bb b--moon-gray'></div>
        <div className='pt3'>
          { scheduleKey === '3' ? 
            <div className='pb3'>
              <div className='f6 fw4'>Find next available time after: </div>
              <div className='pt1'>
                <TimePicker 
                  style={{ }}
                  showSecond={true}
                  defaultValue={moment()}
                  className=""
                  allowEmpty={false}
                  use12Hours={true}
                  inputClassName=''
                  popupStyle={{zIndex: 100000000}}
                  showSecond={false}
                  minuteStep={5}
                  //hideDisabledOptions={true}
                  onChange={e => Dispatch.nextAction('SetScheduledTime', e.toISOString())}
                />
              </div>
              <div className='pt4 f5 fw8'>4) Next Available Time: </div>
              <div className='w-100 pt2 bb b--moon-gray'></div>
            </div>
            :
            null
          }
          { shop?.fulfillment?.bookings === 'loading' ?
           <div key='loading' className='w-100 ph4 flex justify-center'>
              <div className='pt2 w3'>
                <img src={loading} />
              </div>
            </div>
            :
            <div className='pb3 flex flex-column f5'>
              <div className={'f4 fw8 ' + primaryColor }> <span className=' fw7'>{ moment(shop?.fulfillment?.bookings).format('dddd Do h:mm a') }</span></div>
            </div>
          } 
        </div>
        <div className='pt4 w-100 flex justify-center'>
            <SubmitButton id={'pay'} className='pt2' 
                defaultChild={ props => (
                  <div 
                    className={'mt2 ph5 pv3 br2 f4 fw5 pointer bg-' + primaryColor + ' ' + secondaryColor} 
                    onClick={e => Dispatch.nextAction('__.Shop.modal.view', {type: 'customerDetails'})}>
                      Payment
                  </div>
                )}
                loadingChild={ props => (
                  <div key='loading' className='w-100 ph4 flex justify-center'>
                    <div className='w2'>
                      <img src={loading} />
                    </div>
                  </div>
                )}
                errorChild={ props => {
                  return (
                  <div key='error' className='pl3 f5 fw7 flex justify-center red'> 
                    <div>Error: {props.errors?.[0]?.message}</div>
                  </div>
                )}}
                loadingStream={
                  Dispatch.getAction('__.Shop.modal.view')
                  }
                  />
        </div>
      </div>
      <div className='h4 h1-ns'></div>
    </div>
  )
}