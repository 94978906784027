import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => (
  <>
    <ul
      className="social-icons"
      style={{
        textAlign: 'center',
        listStyleType: 'none',
        margin: '25px auto 15px auto',
        padding: 0,
        fontSize: 33,
      }}
    >
      <li>
        <a href={process.env.REACT_APP_FACEBOOK_URL} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={['fab', 'facebook']} />
        </a>
      </li>
      <li>
        <a href={process.env.REACT_APP_FOURSQUARE_URL} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={['fab', 'foursquare']} />
        </a>
      </li>
      <li>
        <a href={process.env.REACT_APP_INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={['fab', 'instagram']} />
        </a>
      </li>
    </ul>

    <p
      style={{
        width: '90%',
        margin: '25px auto',
        paddingTop: 10,
        paddingBottom: 20,
        borderTop: '1px solid #DAD6D2',
        textAlign: 'center',
        fontSize: 13,
        color: '#716f6d',
        lineHeight: '1.5em',
      }}
    >
      2894 On Main • home of espresso alley
      <br />
      Made in East Troy
    </p>
  </>
)

export default Footer
