import { gql } from '@apollo/client';
import { Dispatch } from '@Corim/architecture-frontend'
import { map, distinctUntilChanged, startWith, filter, tap } from 'rxjs/operators'
import { combineLatest } from 'rxjs'

import _ from 'lodash'

export default () => {
  const GET_CONFIG = gql`
    query settings {
      settings {
        id
        name
        description
        value
      }
    }
  `;

  Dispatch.nextAction('Request.settings', { type: 'query', query: GET_CONFIG, variables: { }, poll:process.env.REACT_APP_POLL_INTERVAL, retry: process.env.REACT_APP_RETRY_INTERVAL })

  Dispatch.getAction('Response.settings')
  .pipe(
    filter(e => _.isNil(e.errors)),
    map(e => e.data.settings),
    map(settings => settings.map(setting => ({ [setting.name]: setting.value })).reduce((acc,next) => ({...acc, ...next}), {}) ),

    // Don't update while polling unless something has changed
    distinctUntilChanged((a, b) => _.isEqual(a, b)),
  )
  .subscribe(e => Dispatch.mergeState('__.Shop', e))
}