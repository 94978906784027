import React from 'react';
import Dispatch from '../../architecture/Dispatch'
import ReactModal from 'react-modal'
import _ from 'lodash';
import './modal.css'

Dispatch.getAction('__.Modal.open')
  .subscribe(e => Dispatch.nextState('modal', { open: true, ...e }))

Dispatch.getAction('__.Modal.close')
  .subscribe(e => Dispatch.nextState('modal', { open: false }))

ReactModal.setAppElement('#root');

const customStyles = {
  content : {
    outline: 'none'
  },
  overlay: {
    top:0, left:0, zIndex: 10000,
  },
};

export default class Modal extends React.Component {
  constructor () {
    super();
  }
  
  render () {
    const type = this.props.modal && this.props.modal.type
    const className = ' absolute w-100 mw6 overflow-y-scroll br3 ba bw1 b--moon-gray shadow-5 bg-white ' + this.props._className
    const overlayClassName = ' fixed w-100 vh-100 flex justify-center items-center bn ' + this.props._overlayClassName
    const newStyle = this.props._style ? this.props._style : {}
    const styles = _.merge(customStyles, newStyle)
    const children = this.props._children
    const shouldCloseOnOverlayClick = this.props.shouldCloseOnOverlayClick ? this.props.shouldCloseOnOverlayClick : false
    const View = children[type]
    if (!_.isNil(type) && _.isNil(View)) {
      throw "View for modal not found"
    }
    return (
      <div >
        <ReactModal 
          style={styles}
          className={className}
          overlayClassName={overlayClassName}
           isOpen={this.props.modal && this.props.modal.open}
           contentLabel=""
           shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
           onRequestClose={e => Dispatch.nextAction('__.Modal.close')}
        >
         
          {type && <View {...this.props}/> }
        </ReactModal>
      </div>
    );
  }
}