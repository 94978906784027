import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch } from '@Corim/architecture-frontend'
import { faShoppingCart, faBars, faMapMarkerAlt, faCalendarCheck, faGrinTongueWink, faUtensils, faShoppingBag } from '@fortawesome/free-solid-svg-icons'

export default (props) => {
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  const view = props.view === 'store' ? true : false
  return(
    <div className=''>
      { view ? 
        <div onClick={e => Dispatch.nextAction('__.Modal.open', { type: 'checkout' })}>
          {props._shopChild(props)}
        </div>
      :
        <div onClick={e=> Dispatch.nextAction('view.store')}>
          {props._child(props)}
        </div>
      }
    </div>
  )
}