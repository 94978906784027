import { gql } from '@apollo/client';
import { Dispatch } from '@Corim/architecture-frontend'
import { of, merge, combineLatest } from 'rxjs'
import { map, filter, withLatestFrom, tap, mergeMap, startWith } from 'rxjs/operators'

import _ from 'lodash'

export default () => {

  const CREATE_ORDER = gql`
  mutation createOrder {
    createOrder
  }
  `;

  const GET_ORDER = gql`
  query order($order: ID!) {
    order(order: $order)
  }
  `;

  const ADD_ITEM = gql`
  mutation addItem(
    $order: ID!, 
    $version: Int!,
    $item: ID!,
    $quantity: String!, 
    $modifiers: [ID],
    $note: String!,
    ){
      addItem(order: $order, version: $version, item: $item, quantity: $quantity, modifiers: $modifiers, note: $note)
    }
  `;

  const REMOVE_ITEM = gql`
  mutation removeItem(
    $order: ID!, 
    $version: Int!,
    $item: ID!
    ){
      removeItem(order: $order, version: $version, item: $item)
    }
  `;

  const UPDATE_QUANTITY = gql`
  mutation updateQuantity(
    $order: ID!, 
    $version: Int!,
    $item: ID!,
    $quantity: String!
    ){
      updateQuantity(order: $order, version: $version, item: $item, quantity: $quantity)
    }
  `;

  const ADD_COUPON = gql`
  mutation addCoupon(
    $order: ID!, 
    $version: Int!,
    $coupon: String!
    ){
      addCoupon(order: $order, version: $version, coupon: $coupon)
    }
  `;

    if (localStorage.getItem('orderVersion') !== '1'){
      localStorage.removeItem('order')
      localStorage.setItem('orderVersion', '1')
    }

  of(localStorage.getItem("order"))
    .pipe(
      filter(e => _.isNil(e))
    )
    .subscribe(e => Dispatch.nextAction('Request.createOrder', { type: 'mutation', query: CREATE_ORDER, variables: { } }))

  of(localStorage.getItem("order"))
    .pipe(
      filter(e => e !== null)
    )
    .subscribe(e => {
      Dispatch.nextAction('Request.getOrder', { type: 'query', query: GET_ORDER, variables: { order: e } })
    })

  // Create new order if current order is complete or null
  Dispatch.getAction('Response.getOrder')
    .pipe(
      map(e => e.data?.order),
      filter(e => _.isNil(e) || e && e.state !== 'OPEN'),
    )
    .subscribe(e => Dispatch.nextAction('Request.createOrder', { type: 'mutation', query: CREATE_ORDER, variables: { } }))

  // Create new order after paying
  Dispatch.getAction('Response.pay')
    .pipe(
      filter(e => _.isNil(e.errors) && (!_.isNil(e.data.pay))),
    )
    .subscribe(e => Dispatch.nextAction('Request.createOrder', { type: 'mutation', query: CREATE_ORDER, variables: { } }))

  // Get new order if somehow order id still exists
  merge(Dispatch.getAction('Response.addItem'), Dispatch.getAction('Response.removeItem'))
    .pipe(
      filter(e => !_.isNil(e.errors)),
      map(e => e.errors?.[0]?.extensions?.code),
      filter(e => e === "BAD_REQUEST"),
    )
    .subscribe(e => Dispatch.nextAction('Request.createOrder', { type: 'mutation', query: CREATE_ORDER, variables: { } }))

      // Get new order if refresh order on pay
  Dispatch.getAction('Response.pay')
  .pipe(
    filter(e => !_.isNil(e.errors)),
    map(e => e.errors?.[0]?.extensions?.code),
    filter(e => e === "RECREATE_ORDER"),
  )
  .subscribe(e => Dispatch.nextAction('Request.createOrder', { type: 'mutation', query: CREATE_ORDER, variables: { } }))

  // Refresh order if version mismatch
  merge(Dispatch.getAction('Response.addItem'), Dispatch.getAction('Response.removeItem'))
    .pipe(
      filter(e => !_.isNil(e.errors)),
      map(e => e.errors?.[0]?.extensions?.code),
      filter(e => e === "VERSION_MISMATCH"),
    )
    .subscribe(e => Dispatch.nextAction('Request.getOrder', { type: 'query', query: GET_ORDER, variables: { order: localStorage.getItem("order") } }))

    // Reset order if somehow paid too many times
    Dispatch.getState('__.Shop.order.pricing.totalRemaining')
      .pipe(
        filter(e => e < 0),
      )
      .subscribe(e => Dispatch.nextAction('Request.createOrder', { type: 'mutation', query: CREATE_ORDER, variables: { } }))

  // Refresh order if error on pay
  Dispatch.getAction('Response.pay')
    .pipe(
      filter(e => !_.isNil(e.errors)),
      // filter(e => {
      //   let userInputError = false
      //   if (Array.isArray(e.errors)) {
      //     e.errors?.forEach(error => {
      //       if (error?.extensions?.code === 'BAD_USER_INPUT') {
      //         userInputError = true
      //       }
      //     })
      //   }
      //   return !userInputError
      // }),
      // filter(e => e)
    )
    .subscribe(e => Dispatch.nextAction('Request.getOrder', { type: 'query', query: GET_ORDER, variables: { order: localStorage.getItem("order") } }))

  combineLatest(Dispatch.getState('__.Shop.tip.percentage'),
    merge(
      Dispatch.getAction('Response.createOrder'), 
      Dispatch.getAction('Response.getOrder'),
      Dispatch.getAction('Response.addItem'),
      Dispatch.getAction('Response.removeItem'),
      Dispatch.getAction('Response.updateQuantity'),
      Dispatch.getAction('Response.addCoupon'),
      Dispatch.getAction('Response.createCustomerAndAttachToOrder')
      )
      .pipe(
        filter(e => _.isNil(e.errors) && (!_.isNil(e.data.createOrder) || 
            !_.isNil(e.data.order) ||
            !_.isNil(e.data.addItem) ||
            !_.isNil(e.data.removeItem) ||
            !_.isNil(e.data.updateQuantity) ||
            !_.isNil(e.data.addCoupon) ||
            !_.isNil(e.data.createCustomerAndAttachToOrder)
          )),
        map(e => {
          if (!_.isNil(e.data.createOrder)) {
            return e.data.createOrder
          } 
          else if (!_.isNil(e.data.order)) {
            return e.data.order
          }
          else if (!_.isNil(e.data.addItem)) {
            return e.data.addItem
          }
          else if (!_.isNil(e.data.removeItem)) {
            return e.data.removeItem
          }
          else if (!_.isNil(e.data.updateQuantity)) {
            return e.data.updateQuantity
          }
          else if (!_.isNil(e.data.addCoupon)) {
            return e.data.addCoupon
          }
          else if (!_.isNil(e.data.createCustomerAndAttachToOrder)) {
            return e.data.createCustomerAndAttachToOrder.order
          }
        }),
      ),
      Dispatch.getState('__.Shop.bookings')
        .pipe(
          startWith([])
        ),
      Dispatch.getState('__.Shop.items')
        .pipe(
          startWith([])
        ),
    )
    .pipe(
      mergeMap(([tipPercent, response, bookings, items]) => of(1)
        .pipe(
          // Set Tip
          map(() => {
            localStorage.setItem("order", response.id)
            const preTipTotal = parseInt(response.totalMoney?.amount) ?? 0
            const totalDiscount = parseInt(response.totalDiscountMoney?.amount) ?? 0
            const totalTax = parseInt(response.totalTaxMoney?.amount) ?? 0
            const subTotal = preTipTotal - totalTax
            const tip = parseInt(subTotal * (parseInt(tipPercent)/100) )
            const total = preTipTotal + tip
            const tipPaid = _.filter(response?.tenders ?? [], tender => (tender.cardDetails.status !== 'VOIDED' && tender.cardDetails.status !== 'FAILED'))?.reduce((acc,next) => acc + parseInt(next?.tipMoney?.amount), 0) ?? 0
            const totalAmountPaid = _.filter(response?.tenders ?? [], tender => (tender.cardDetails.status !== 'VOIDED' && tender.cardDetails.status !== 'FAILED'))?.reduce((acc,next) => acc + parseInt(next?.amountMoney?.amount), 0) ?? 0
            const amountPaid = totalAmountPaid > 0 ? totalAmountPaid - tipPaid : 0
            const tipRemaining = tip - tipPaid
            const amountRemaining = preTipTotal - amountPaid
            const totalRemaining = tipRemaining + amountRemaining
            return { 
              id: response.id, 
              version: response.version, 
              details: response,
              line_items: response.lineItems,
              pricing: {
                total,
                totalDiscount,
                totalTax,
                subTotal,
                tip,
                preTipTotal,
                tipRemaining,
                amountRemaining,
                totalRemaining
              }
            }
          }),
          // Set Bookings
          map(order => ({...order, line_items: order.line_items?.map(item => ({...item, booking: _.find(bookings, booking => booking.referenceId === item.catalog_object_id )})) ?? [] })),
          // Set All At Once Booking
          map(order => ({...order, latestBooking: _.last(_.orderBy(bookings, booking => booking.endTime))})),
          // Set Item ID on variations
          map(order => ({...order, line_items: order.line_items.map(orderItem => ({
            ...orderItem,
            itemId: _.find(items, item => _.some(item.variations, variation => variation.id === orderItem.catalog_object_id))?.id
           })) })),
        )
      )
    )
    .subscribe(e => Dispatch.mergeState('__.Shop.order', e))

  Dispatch.getAction('order.addItem')
    .pipe(
      withLatestFrom(
        Dispatch.getState('__.Shop.order'), 
        Dispatch.getState('MultiSelect.cart'), 
        Dispatch.getState('Textbox.cart.note'))
    )
    .subscribe(([z_item, order, cart, note]) => {
      const item = _.findKey(cart.variations, variation => variation === true) ? _.findKey(cart.variations, variation => variation === true) : z_item
      const modifiers = cart.modifiers && Object.keys(cart.modifiers) ? Object.keys(cart.modifiers) : []
      Dispatch.nextAction('Request.addItem', { 
      type: 'mutation', 
      query: ADD_ITEM, 
      variables: {
        order: order.id, 
        version: parseInt(order.version),
        item,
        note, 
        modifiers: modifiers.map(key => _.pickBy(cart.modifiers[key], mod => mod === true )).map(mod => Object.keys(mod)).reduce((acc, next) => ([...next, ...acc]), []),
        quantity: _.findKey(cart.quantity, quantity => quantity === true),
      } 
    })})

  Dispatch.getAction('order.removeItem')
    .pipe(
      withLatestFrom(Dispatch.getState('__.Shop.order'))
    )
    .subscribe(([ item, order ]) => Dispatch.nextAction('Request.removeItem', { 
        type: 'mutation', 
        query: REMOVE_ITEM, 
        variables: {
          order: order.id, 
          version: parseInt(order.version),
          item,
        }
      }))

  Dispatch.getAction('order.addCoupon')
    .pipe(
      withLatestFrom(Dispatch.getState('Textbox.cart.coupon'), Dispatch.getState('__.Shop.order.details'))
    )
    .subscribe(([, coupon, order ]) => Dispatch.nextAction('Request.addCoupon', { 
        type: 'mutation', 
        query: ADD_COUPON, 
        variables: {
          order: order.id, 
          version: parseInt(order.version),
          coupon,
        }
      }))

  Dispatch.getAction('__.Select.update.cart.quantities.*')
    .pipe(
      map(update => ({id: update.id, quantity: update.value.value})),
      withLatestFrom(Dispatch.getState('__.Shop.order'))
    )
    .subscribe(([item, order]) => Dispatch.nextAction('Request.updateQuantity', { 
      type: 'mutation', 
      query: UPDATE_QUANTITY, 
      variables: {
        order: order.id, 
        version: parseInt(order.version),
        item: item.id,
        quantity: item.quantity.toString()
      }
    }))

    Dispatch.getState('MultiSelect.cart.schedule')
      .subscribe(e => Dispatch.mergeState('__.Shop.order.fullfillment', { schedule: e }))

    Dispatch.getState('MultiSelect.cart.method')
      .pipe(
        startWith({1: true})
      )
      .subscribe(e => Dispatch.mergeState('__.Shop.order.fullfillment', { method: e, schedule: { 1: true } }))
      
    merge(Dispatch.getAction('SetScheduledTime'), 
        merge(Dispatch.getState('__.Shop.order.fullfillment.schedule'), Dispatch.getState('__.Shop.order.fullfillment.method'))
        .pipe(
          map(e => null)
        )
    )
      .pipe(

      )
      .subscribe(e => Dispatch.mergeState('__.Shop.order.fullfillment', { time: e }))
}