import React from 'react';
import _ from 'lodash'
import BaseComponent from '../architecture/BaseComponent'
import { map, startWith, switchMap, delay } from 'rxjs/operators'
import { merge, combineLatest, of } from 'rxjs'

const Button = (props) => {
  const className = props.className ? props.className : ''
  const Default = props.defaultChild ? props.defaultChild : null
  const Error = props.errorChild ? props.errorChild : null
  const Loading = props.loadingChild ? props.loadingChild : null
  const Success = props.successChild ? props.successChild : null
 // const state = props.state ? props.state : {}
  const stateObject = {
    none: Default,
    error: Error,
    loading: Loading,
    success: Success,
  }
  const State = stateObject[props.state]
  //const State = state === 'error' ? Error : response.data ? Default : props.response === 'loading' ? Loading : Default
  return (
    <div className={className}>
      <State {...props}/>
    </div>
  )
}

const store$ = (props) => {
  const showSuccess = props.showSuccess ? props.showSuccess : false
  const loadingStream$ = props.loadingStream ? props.loadingStream : of(1)
  const successStream$ = props.successStream ? props.successStream : of(1)
  const errorStream$ = props.errorStream ? props.errorStream : of(1)
  return combineLatest(
    merge(
      loadingStream$
        .pipe(
          map(e => 'loading')
        ),
      errorStream$
        .pipe(
          switchMap(e => of(1)
            .pipe(
              delay(2600),
              map(() => 'none'),
              startWith('error')
            )
          )  
        ),
        successStream$
          .pipe(
            switchMap(e => showSuccess ? 
              of('none')
                .pipe(
                  delay(2000),
                  startWith('success')
                )
              :
              of('none')
            )
          )
      )
      .pipe(
        startWith('none')
      ),
      errorStream$
      .pipe(
        map(e => e.errors),
        startWith({})
      )
    )
    .pipe(
      map(([
        state,
        errors
      ])=>
      ({
        state,
        errors
      }))
    )
  }

export default (props) => <BaseComponent Component={Button} store={store$(props)} {...props}/>