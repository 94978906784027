import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch } from '@Corim/architecture-frontend'
import { faParachuteBox, faArrowAltCircleLeft, faBars, faFish, faPlus} from '@fortawesome/free-solid-svg-icons'


export default (props) => {
  const payment = props.modal && props.modal.payment ? props.modal.payment : {}
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  const PaymentSuccess = shop.PaymentSuccess
  const primaryColor = shop.primaryColor
  const secondaryColor = shop.secondaryColor
  const readyTime = '6:45'
  return(
    <div className={'min-vh-100 w-100 pa4 white bg-' + primaryColor }>
      <div className='flex items-center'>
        <div 
          className={'f2 pr3 fw8 ' + secondaryColor }
          onClick={e => Dispatch.nextAction('__.Modal.close')}>
            
              <FontAwesomeIcon icon={faArrowAltCircleLeft} />

        </div>
        <div className={'pt2 f3 fw7 ' + secondaryColor }>Payment Success!</div>
      </div>
      <div className='w-100 h-100 mt3 pt5 bt b--moon-gray'>
        {/* { payment.status === 'COMPLETED' ?  */}
          <PaymentSuccess {...props} />
          {/* :
          null
        } */}
      </div>
    </div>
  )
}