import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Dispatch, Textbox, MultiSelect } from '@Corim/architecture-frontend'
import _ from 'lodash'

export default (props) => {
  const primaryColor = props.__?.Shop?.primaryColor
  const secondaryColor = props.__?.Shop?.secondaryColor
  const categorySearch = props.__?.Shop?.categorySearch
  const options = categorySearch.map(option => {
    const TipMultiButton = (props) => {
      const selected = props.selected
      const selectedClass = selected ? ' underline fw7 ' : ''
      return (
            <div className={'mt1 mt0-ns ml2 mr2 f6 pointer ' + selectedClass + ' ' + secondaryColor }>
              {option.name}
            </div>
      )
    }
    return {id: option.name, component: TipMultiButton}
  })
  return (
    <div className='ph2 flex items-center flex-wrap justify-center'>
      <div className='w-100 mw6-ns mr4-ns mb2 mb0-ns ba br2 b--moon-gray flex items-center justify-between bg-white'>
        <div className='moon-gray pl2 '><FontAwesomeIcon icon={faSearch} /></div>
        <Textbox
          className='input-reset w-90 pv2 pr3 dark-gray fw4 '
          id='search'
          placeholder='What are you looking for?'
          style={{ outline: 'none', borderStyle: 'none' }}
          type='text'
          defaultValue={''}
        />
      </div>
      { categorySearch ? <div className=' fw6 f7'>
        <MultiSelect className='w-100 flex flex-wrap justify-between' options={options} id={'category'} isMultiSelect={false} defaultValue={{['All']: true}}/>
        {/* <div className='pr2 orange underline'>All</div>
        <div className='pr2 orange'>Canned</div>
        <div className='pr2 orange'>Produce</div>
        <div className='pr2 orange'>Meat</div>
        <div className='pr2 orange'>Dairy</div>
        <div className='pr2 orange'>Dessert</div>
        <div className=' orange'>Other</div> */}
      </div>
      :
      <div className='w-100 pt2'></div>
      }
    </div>
  )
}