import React from 'react';
import { Dispatch, Textbox, Modal, SubmitButton, BaseComponent } from '@Corim/architecture-frontend'
import { ShoppingIcon, Store, SearchBar } from '@Corim/squareshop'
import _ from 'lodash'

import imgLogo from '../../media/images/logo.svg';

import { Link, withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCarrot, faShoppingBag, faPizzaSlice } from '@fortawesome/free-solid-svg-icons'

// Modal Views
import { Payment, Checkout, ThankYou, ItemDetail, Fullfillment, CustomerDetails }  from '@Corim/squareshop'
import '../../settings'

const Header = (props) => {
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  const count = shop?.order?.line_items?.reduce((acc, next) => { return parseInt(next.quantity) + acc }, 0)
  const child = (props) => {
    return (
      <div className='black fw6 f5 f4-ns'>eat local, shop local, live local</div>
    )
  }
  const shopChild = (props) => {
    return (
      <div className='f2 black flex items-center pointer'>
            <div className='w1 relative flex justify-center' style={{left: 24, top: 7}}>
              {count ? <div className='w-100 f5 fw7 tc white'>
                {count}
              </div>
              :
              null
            }
            </div>
            <FontAwesomeIcon icon={faShoppingBag} />
        </div>
 
    )
  }
  return (
    <div className='fixed w-100 ph3 ph4-ns bg-white black flex justify-between items-center' style={{zIndex: 1000, height: 70}}>
      {/* <div className='relative' style={{left: -10}}><Menu /></div> */}
      <div className='h-100 pv2 relative'><Link to="/"><img className='h-100' src={imgLogo}/></Link></div>
      <ShoppingIcon _child={shopChild} _shopChild={shopChild} {...props} />
      
    </div>
  )
}

const modalChildren = {
  item: ItemDetail,
  checkout: Checkout,
  payment: Payment,
  thankyou: ThankYou,
  fullfillment: Fullfillment,
  customerDetails: CustomerDetails
}

Dispatch.nextState('view', 'store')

const Shop = (props) => {
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  // const viewState = props.view ? props.view : 'home'
  // const views = {
  //   home: Homepage,
  //   store: Store,
  //   terms: Terms,
  // }
  // const View = views[viewState]
  return (
    <div className='min-vh-100 w-100 bg-white'>
      <Modal _className=' modal-size modal-size-ns ' shouldCloseOnOverlayClick={true} _children={modalChildren} _style={{overlay: {backgroundColor: 'rgb(0,0,0,.3)'}}} { ...props } />
      <div className='flex flex-column'>
        <Header {...props}/>
        <div style={{top: 70, height: 45, zIndex:900}} className='relative w-100 bg-dark-gray tc white f5 fw7 bb b--gray flex items-center justify-center'>
          <div className=''>{shop.headerMessage}</div>
        </div>
      </div>
      <div id='dont wrap' className='w-100 relative' style={{top: 70, flexWrap: 'wrap!'}}>
        <Store {...props}/>
      </div>
    </div>
  )
}

export default (props) => <BaseComponent Component={Shop} {...props} />