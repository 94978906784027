import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Dispatch, Textbox } from '@Corim/architecture-frontend'
import _ from 'lodash'
import loadingIcon from '../../media/loading.svg'
import SearchBar from './Searchbar'

const Item = (props) => {
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  const item = props.z_item ? props.z_item : {}
  const image = _.find(shop.images, i => i.id === item.image_id)
  const url = image ? image.image_data.url : ''
  const variations = item.item_data && item.item_data.variations
  const amount = item.sold_out ? 'Sold Out' : variations.length > 1 ? 'Variable Cost' : variations[0].item_variation_data.price_money && 
    variations[0].item_variation_data.price_money.amount ? 
      '$' + (variations[0].item_variation_data.price_money.amount/100).toFixed(2)
      :
      'No Price'
  return (
    <div key={item.id} className='w-100 w-33-ns mw6 mt2 mb2 h4 bg-white pointer flex' onClick={e => Dispatch.nextAction('__.Shop.item.select', item.id )}>
      <div className='w-100 mr2-ns dark-gray ba br3 b--moon-gray flex'>
        <div className='ph3 w-75 flex flex-column justify-center tl'>
          <div>
            <div className='f4 fw7'>{item.item_data.name}</div>
            <div className='mt2 f6 fw4 overflow-hidden' style={{maxHeight: 33}}>{item.item_data.description}</div>
          </div>
          {/* The varations will be an issue with pricing */}
          <div className='pt2 f6 fw7'>{amount}</div>
        </div>
        <div className='w-30 flex items-center' >
          {url ? <img style={{height:'100%', objectFit: 'cover'}} className='w-100 br3 br--right' src={url} /> : null}
        </div>
      </div>
    </div>
  )
}

const Items = (props) => {
  const items = props.z_items && props.z_items ? props.z_items : []
  return (
    <div className='flex justify-center flex-wrap'>{items.map(item => <Item key={item.id} z_item={item} {...props}/> )}</div>
  )
}

export default (props) => {
  const shop = props?.__?.Shop ?? {}
  const primaryColor = shop.primaryColor
  const secondaryColor = shop.secondaryColor
  const categories = shop?.categories ?? []
  const allItems = shop?.filteredItems ?? []
  const loading = shop?.loading?.items ?? true
  return (
    <div className='w-100'>
        <div className='w-100 flex justify-center'>
          <div className='w-100 pb5 flex flex-column items-center'>
            {/* actual top is 64, but trying to overlap the header */}
            <div className={'w-100 pt2 pb2 bb b--moon-gray bg-' + primaryColor } style={{position: 'sticky', top:70, zIndex: 10000}} >
              <div className='w-100 ph2'><SearchBar {...props}/></div>
            </div>
            {
              loading ? 
              <div className='pt5 w-100 flex items-center justify-center'>
                <div className='w-100 mw4'><img className='w-100' src={loadingIcon} /> </div>
              </div>
            :
              <div id='itemList' className='w-100 min-vh-50 mw9 ph2 ph3-ns'>
              {categories.map(category => {
                const vendor = category.vendor_data
                const categoryId = category.id
                const items = _.filter(allItems, item => item.item_data.category_id === categoryId)
                const logo = vendor && vendor.logo
                const description = vendor && vendor.description
                const subDescription = vendor?.subDescription
                const website = vendor && vendor.website
                return (
                  <>
                  {(items.length > 0) ?
                    <div key={category.id} className='mt3 pt4 pb3'>
                      {logo ? 
                        <div className='w-100 flex justify-center'><div className='w-90 mw6 pb3'><a href={website} target='_blank'><img className='w-100' src={logo} style={{objectFit: 'contain', maxHeight: 170}}/></a></div></div>
                        : 
                        <div className={'pl1 f3 f2-ns fw9 tl tc-ns ' + primaryColor }>{category.category_data && category.category_data.name}</div>
                      }
                      {description && <div className='w-100 pl1 pt2 f5 f4-ns tl tc-ns fw7 fw7-ns' style={{whiteSpace: 'pre-line'}}>{description}</div>}
                      {subDescription && <div className='w-100 pl1 pt1 f6 f5-ns tl tc-ns fw4 fw4-ns' style={{whiteSpace: 'pre-line'}}>{subDescription}</div>}
                      {/* {website ? <div className='w-100 ph3 tc fw9 f6 pb3'><a href={website}>Website</a></div> : null } */}
                      {/* {description ? <div className='w-100 ph3 pt3 tc'>{description}</div> : null } */}
                      <div className='pt2 pt4-ns'>
                        <Items z_items={items} {...props}/>
                      </div>
                    </div>
                     :
                     null
                     }
                  </>
                )
              })}
            </div>
            }
          </div>
      </div>
    </div>
  )
}