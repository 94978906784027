import _ from 'lodash'
import { of} from 'rxjs'

// const keyReducer = (object, keypath) => keypath.split('.').reduce((prev, next, index, source) => {
//   console.log('first')
//     console.log(prev)
//    console.log(next)
//             if (_.isUndefined(prev.value)) {
//          //     console.log("here1")
//               return { value: undefined, observable: of(undefined) }
//             }
//             else if (_.isNull(prev.value)) {
//               return { value: null, observable: of(null) }
//             }
//             else if (next === '*') {
//               //forarray 
//               console.log(prev.value)
//               //const moleObservable = prev.observable
//               if (_.isObject(prev.value)) {
//                 const reduced = Object.keys(prev.value)
//                   .map(key => ({id: key, value: keyReducer( { value: prev.value[key], observable: prev.observable} , source.slice(index + 1, source.length).reduce((acc, next) => acc+'.'+next,'').substring(1))}))
//                   .map(item => Object.keys(item.value)
//                     .map(key => ({ [key]: item.value[key] }))
//                     .reduce((accValues, nextProperty) => ({ ...accValues, ...nextProperty }), {}),
//                     {}
//                   )
//                   .map(item => item.value)
//                   //.map(value => Object.keys(value) => )
//                   .reduce((acc, nextObject) => ({ ...acc, ...nextObject}), {})
//                   //   .reduce((accValues, nextProperty) => ({ ...accValues, ...nextProperty }), {}),
//                   //   {}
//                   // ) //({ ...acc, [next.id]: next.value }), {})
//                 //Object.keys(reduced).map(key => ({key: reduced[key]}))
//                 source.splice(1)
//                 console.log('reduced')
//                 console.log(reduced)
//                 return { value: reduced, observable: prev.observable.pipe(mergeMap(e => from(Object.keys(reduced).map(key => ({[key]: reduced[key]}))))) }
//               }
//               //This functionality is untested
//               // else if (_.isArray(prev.value)) {
//               //   const reduced = prev.map(item => ({id: key, value: keyReducer(item, source.slice(index + 1, source.length).reduce((acc, next) => acc+'.'+next,'').substring(1))}))
//               // }
//               else {
//                 return { value: undefined, observable: of(undefined) }
//               }
//               // { ...prev, __previous: true }
//             }
//             // This is for when using * at the end, shouldnt be done, but can be.
//             else if (_.isUndefined(next))
//             {
//               return prev.value
//             }
//             // Can't do this because it needs an array modifier to search for in the array
//             // else if (_.isArray(prev)) {
//             //   return _.find(prev, item => item[next])
//             // }
//        ///     console.log("here2")
//             console.log('returning')
//             console.log(prev.value[next])
//             return { value: prev.value[next], observable: of(prev.value[next]) }
//         }, object)

//export default keyReducer
       // _.isUndefined(prev) ? undefined : _.isNull(prev) ? null : prev[next], object)


 //      import _ from 'lodash'
//import { of, from, empty } from 'rxjs'
//import { mergeMap } from 'rxjs/operators'

const keyReducer = (object, keypath) => {
  let observable = of(1)
  keypath.split('.').reduce((prev, next, index, source) => {
            if (_.isUndefined(prev)) {
              observable = of(undefined)
              return undefined
            }
            else if (_.isNull(prev)) {
              observable = of(null)
              return null
            }
            // Works but minimally useful
            // else if (next === '*') {
            //   if (_.isObject(prev)) {
            //     observable = from(Object.keys(prev).map(key => ({ [key]: prev[key] })))
            //     return 1000
            //   }
            //   else {
            //     observable = of(undefined)
            //     return undefined
            //   }
            // }
            observable = of(prev[next])
            return prev[next]
        }, object)
  return observable
}

export default keyReducer