import React from 'react'
import Dispatch from '../../architecture/Dispatch'
import BaseComponent from '../../architecture/BaseComponent'
import { map, switchMap, take, mergeMap, withLatestFrom, bufferTime, distinctUntilChanged, tap, startWith, share, filter } from 'rxjs/operators'
import { zip, merge } from 'rxjs'
import _ from 'lodash'


const getDispatchIds = (id) => {
  const idArray = id.split('.')
  let idString = idArray.reduce((acc, next, index) => index + 1 !== idArray.length ? acc + '.' + next : acc, '')
  idString = idString ? idString : ''
  return {
    idString,
    idValue: idArray[idArray.length - 1]
  }
}

// Removes all self values from the dispatcher state
// Dispatch.getAction('MultiSelect', 'remove')
//   .pipe(
//     map(e => getDispatchIds(e)),
//     switchMap(ids => Dispatch.getState('MultiSelect' + ids.idString)
//       .pipe(
//         take(1),
//         map((state) => {
//           const { [ids.idValue]: value,...newState } = state
//           return newState
//         }),
//         map(newState => ({ids, state: newState}))
//     )),
//   )
//   .subscribe(e => {
//     Dispatch.nextState('MultiSelect' + e.ids.idString, e.state)
//   })


// Removes all self values from the dispatcher state
const newAction$ = zip(
  merge(
    Dispatch.getAction('MultiSelect.remove')
      .pipe(
        map(e => ({ type: 'remove', details: e }))
      ),
    Dispatch.getAction('MultiSelect.add')
      .pipe(
        map(e => ({ type: 'add', details: e }))
      )
    //   .pipe(
    //     map(e => ({type: 'remove', value: e}))
    //   ),
    // Dispatch.getAction('MultiSelect.mount')
    //   .pipe(
    //     map(e => ({type: 'mount', value: e}))
    //   )
  ), 
  Dispatch.getAction('MultiSelect.complete').pipe(startWith(1))
).pipe(
  share()
)

newAction$
  .pipe(
    filter(([e, complete]) => e.type === 'remove'),
    map(([e, complete]) => e.details),
    map(e => getDispatchIds(e)),
    mergeMap(ids => Dispatch.getState('MultiSelect' + ids.idString)
      .pipe(
        take(1),
        map((state) => {
          const { [ids.idValue]: value,...newState } = state
          return newState
        }),
        map(newState => ({ids, state: newState}))
    )),
  )
  .subscribe(e => {
    Dispatch.nextState('MultiSelect' + e.ids.idString, e.state)
    Dispatch.nextAction('MultiSelect.complete')
  })

newAction$
  .pipe(
    filter(([e, complete]) => e.type === 'add'),
    map(([e, complete]) => e.details),
    tap(e => {
      const defaultValue = e.defaultValue
      const dispatchIds = getDispatchIds(e.id)

      //Dispatch.nextAction('MultiSelect', 'mount', {defaultValue, dispatchIds})

      //Sets a default value in the dispatcher state
      if (_.isNil(e.value)){
        Dispatch.mergeState('MultiSelect' + dispatchIds.idString, { [dispatchIds.idValue]: defaultValue ? defaultValue : {} })
      }

      const multiSelect = _.isNil(e.isMultiSelect) ? true : e.isMultiSelect
      const unselectable = _.isNil(e.unselectable) ? false : e.unselectable
      
      Dispatch.mergeState('MultiSelect.options' + dispatchIds.idString + '.' + dispatchIds.idValue, { isMultiSelect: multiSelect, unselectable })
    })
  )
  .subscribe(e => {
    // Dispatch.nextState('MultiSelect' + e.ids.idString, e.state)
    Dispatch.nextAction('MultiSelect.complete')
  })
    

// Maps any multi select click to the appropriate dispatcher state change
Dispatch.getAction('MultiSelect.click.*')
  .pipe(
    mergeMap(e => Dispatch.getState('MultiSelect.' + e.id)
      .pipe(
        take(1),
        withLatestFrom(Dispatch.getState('MultiSelect.options.' + e.id)),
        map(([value, options]) => {
          if (options.isMultiSelect) {
            const newValue = value[e.value] ? !value[e.value] : true
            return { id: e.id, value: { ...value, [e.value]: newValue }}
          }
          else {
            if (options.unselectable && value[e.value]) {
              return { id: e.id, value: { [e.value]: false }}
            }
            else {
              return { id: e.id, value: { [e.value]: true }}
            }
          }
        }
      )
    )
  ),
  )
  .subscribe(e => Dispatch.nextState('MultiSelect.' + e.id, e.value))

class MultiSelectWrapper extends React.Component {
  componentDidMount() {
    Dispatch.nextAction('MultiSelect.add', this.props)
  }
  componentWillUnmount() {

    //Removes from dispatcher state
    Dispatch.nextAction('MultiSelect.remove', this.props.id)

    //Removes from dispatcher state
    // const ids = getDispatchIds(this.props.id)
    // Dispatch.getState('MultiSelect' + ids.idString)
    //   .pipe(
    //     take(1),
    //     map((state) => {
    //       const { [ids.idValue]: value,...newState } = state
    //       return newState
    //     }),
    //     map(newState => ({ids, state: newState}))
    // ).subscribe(e => {
    //   Dispatch.nextState('MultiSelect' + e.ids.idString, e.state)
    // })
  }
  render() {
    const id = this.props.id
    const key = this.props.__key
    const className = this.props.className
    const value = this.props.value ? this.props.value : {}
    const style = this.props.style
    const onKeyDown = this.props.onKeyDown
    const onBlur = this.props.onBlur
    const onChange = this.props.onChange
    const placeholder = this.props.placeholder
    const defaultValue= this.props.defaultValue
    const menuPlacement = this.props.menuPlacement ? this.props.menuPlacement : 'auto'

    const dispatchIds = getDispatchIds(id)

    const options = this.props.options
    return (
      <div 
        id={id}
        key={key ?? id}
        className={className ? className : 'w-100 flex flex-wrap'}>
        { options.map(option => {
        const selected = value[option.id] ? true : false
        const Child = option.component
        return (
          <div
            id={option.id}
            key={option.id}
            onClick={e => Dispatch.nextAction('MultiSelect.click.' + id, option.id )}><Child selected={selected} />
          </div>)
        })}
      </div>
    )
  }
}


export default (props) => <BaseComponent 
  Component={MultiSelectWrapper} 
  store={props.stream ? 
    props.stream 
    : 
    Dispatch.getState('MultiSelect.' + props.id)
      .pipe(
        map(e => ({ value: e }))
      )
  } {...props}/>