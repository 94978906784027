import { Dispatch } from '@Corim/architecture-frontend'
import { startWith, map, filter, delay, switchMap, takeUntil, skip, withLatestFrom, share } from 'rxjs/operators'
import { timer, of, merge } from "rxjs";
import ReactPixel from 'react-facebook-pixel';

// Dispatch.getAction('place.submit')
//   .subscribe(e => ReactPixel.track( 'ViewContent') )
//   //.subscribe(e => window.wrappedFBQ('track', 'ViewContent', { value: 1, currency: 'USD'}))

  // Dispatch.getAction('__.Shop.item.select')
  // .subscribe(e => ReactPixel.track( 'ViewContent' ) )
  // //.subscribe(e => window.wrappedFBQ('track', 'ViewContent', { value: 1, currency: 'USD'}))

  Dispatch.getAction('Request.addItem')
  .subscribe(e => ReactPixel.track( 'AddToCart' ) )
  //.subscribe(e => window.wrappedFBQ('track', 'Purchase', { value: 10, currency: 'USD'}))


  Dispatch.getAction('__.Modal.open')
    .pipe(
      filter(e => e.type === 'payment')
    )
  .subscribe(e => ReactPixel.track( 'InitiateCheckout' ) )
  //.subscribe(e => window.wrappedFBQ('track', 'Purchase', { value: 10, currency: 'USD'}))

  // Dispatch.getAction('Request.addItem')
  // .subscribe(e => ReactPixel.track( 'AddPaymentInfo' ) )
  // //.subscribe(e => window.wrappedFBQ('track', 'Purchase', { value: 10, currency: 'USD'}))

Dispatch.getAction('Request.pay')
  .subscribe(e => ReactPixel.track( 'Purchase', { value: ((e.variables?.amount ?? 0)/100), currency: 'USD'} ) )
  //.subscribe(e => window.wrappedFBQ('track', 'Purchase', { value: 10, currency: 'USD'}))

// Dispatch.getAction('subscribe')
//   .subscribe(e => ReactPixel.track( 'Subscribe', { value: 5, currency: 'USD' } ) )
//   //.subscribe(e => window.wrappedFBQ('track', 'Subscribe', { value: 5, currency: 'USD' }))