import {  of, from, timer, throwError } from "rxjs";
import {  map, switchMap, mergeMap, groupBy, catchError, tap, retryWhen, delay } from 'rxjs/operators'

export default (Dispatch, client) => {
  
  const executeRequest = (request) => {
    const variables = request.variables ? request.variables : {}
    return of(1)
      .pipe(
        tap(e => {
          if (request.type !== 'query' && request.type !== 'mutation') {
            throw new Error("Invalid Request Type (should be query or mutation)")
          }
        }),
        mergeMap(() => (request.type === 'query' ? 
          from(client
            .query({ query: request.query, variables }))
          : 
          from(client
            .mutate({ mutation: request.query, variables }))
          )
          .pipe(
            map(response => { 
              if (request.retry && response.errors) {
                throw new Error(response.errors)
              }
              return response
            })
          )
        ),
        retryWhen(err => err.pipe(
          mergeMap((error, i) => {
            const attempts = i + 1
            if (request.retry && (attempts < (request.attempts ? (request.attempts) : 1000000000))) {
              return of(1).pipe(delay(request.retry * attempts))
            }
            else {
              return throwError(error)
            }
          })
        ))
      )    
  }

  Dispatch.getAction('Request.*')
    .pipe(
      groupBy(e => e.id),
      mergeMap(group => group
        .pipe(
          map(e => e.value),
          switchMap(request => (request.poll ? timer(0, request.poll) : of(1))
            .pipe(
              switchMap(() => executeRequest(request)
                .pipe(
                  catchError(err => of({errors: err.message})),
                )
              ),
              map(response => ({id: group.key, response: response})),
            )
          )
        ),
      ),
    )

  .subscribe(e => Dispatch.nextAction('Response.' + e.id, e.response))
}