// import {
//   SquarePaymentForm,
//   CreditCardNumberInput,
//   CreditCardExpirationDateInput,
//   CreditCardPostalCodeInput,
//   CreditCardCVVInput,
//   CreditCardSubmitButton,
//   ApplePayButton,
//   GooglePayButton,
//   GiftCardInput
// } from 'react-square-payment-form'
import moment from 'moment'
// import 'react-square-payment-form/lib/default.css'
import loading from '../../media/loading.svg'

import React from 'react';
// import { faUtensils } from '@fortawesome/free-solid-svg-icons'
import { Dispatch, Textbox, SubmitButton } from '@Corim/architecture-frontend'
import { wrap } from '../../../build/Store/components/Payment';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faParachuteBox, faArrowAltCircleLeft, faBars, faFish, faPlus} from '@fortawesome/free-solid-svg-icons'
import ModalNavigator from './ModalNavigator';
import { toPromise } from 'rxjs'
import { tap, map, startWith, mergeMap, switchMap, filter, take, delay } from 'rxjs/operators'

//import { CreditCard, PaymentsForm } from 'react-square-web-payments-sdk';

// const SANDBOX_APPLICATION_ID='sandbox-sq0idb-n9fHlHoOM9nbNf7t8jyAAg'
// const SANDBOX_LOCATION_ID='R2P9JZRXJ19BE'

 const wrappedRequest = (pricing) => {
      return {
        requestShippingContact: false,
        requestBillingContact: false,
        currencyCode: "USD",
        countryCode: "US",
        total: {
          label: "Total",
          amount: (pricing?.totalRemaining/100).toString(),
          pending: false
        },
        lineItems: [
          {
            label: "Subtotal",
            amount: (pricing?.subTotal/100).toString(),
            pending: false
          },
          {
            label: "Tax",
            amount: (pricing?.totalTax/100).toString(),
            pending: false
          },
          {
            label: "Tip",
            amount: (pricing?.tip/100).toString(),
            pending: false
          },
          {
            label: "Discount",
            amount: (pricing?.totalDiscount/100).toString(),
            pending: false
          }
        ]
      }
    }


  async function createPayment(token) {
    Dispatch.nextAction('cart.pay', {nonce: "dskaasfdkjfsadjk", token})
  }

  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }

      throw new Error(errorMessage);
    }
  }


    // Checkpoint 2.
    async function handlePaymentMethodSubmission(event, paymentMethod) {
      event.preventDefault();
      console.log(paymentMethod)
      try {
        // disable the submit button as we await tokenization and make a payment request.
       // cardButton.disabled = true;
        const token = await tokenize(paymentMethod);
        const paymentResults = await createPayment(token);
       // displayPaymentResults('SUCCESS');

        console.debug('Payment Success', paymentResults);
      } catch (e) {
      //  cardButton.disabled = false;
      //  displayPaymentResults('FAILURE');
        console.error(e.message);
      }
    }

class Payment extends React.Component {
  async componentDidMount(){
    const appId = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_APPLICATION_ID : process.env.REACT_APP_DEV_APPLICATION_ID
    const locationId = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SQUARE_LOCATION_ID : process.env.REACT_APP_DEV_SQUARE_LOCATION_ID
  
    async function initializeCard(payments) {
      const card = await payments.card();
      await card.attach('#card-container');
      return card;
    }
  
    async function createPayment(token) {
      Dispatch.nextAction('cart.pay', {nonce: "dskaasfdkjfsadjk", token})
    }
  
    async function tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(
            tokenResult.errors
          )}`;
        }
  
        throw new Error(errorMessage);
      }
    }
  
    // status is either SUCCESS or FAILURE;
    function displayPaymentResults(status) {
      const statusContainer = document.getElementById(
        'payment-status-container'
      );
      if (status === 'SUCCESS') {
        statusContainer.classList.remove('is-failure');
        statusContainer.classList.add('is-success');
      } else {
        statusContainer.classList.remove('is-success');
        statusContainer.classList.add('is-failure');
      }
  
      statusContainer.style.visibility = 'visible';
    }
  
      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }
  
      let payments;
      try {
        payments = window.Square.payments(appId, locationId);
      } catch (err) {
        console.log("Error Loading Payments")
        console.log(err)
        const statusContainer = document.getElementById(
          'payment-status-container'
        );
        statusContainer.className = 'missing-credentials';
        statusContainer.style.visibility = 'visible';
        return;
      }
  
      let card;
      try {
        card = await initializeCard(payments);
      } catch (e) {
        console.error('Initializing Card failed', e);
        return;
      }
  
      // Checkpoint 2.
      async function handlePaymentMethodSubmission(event, paymentMethod) {
        event.preventDefault();
  
        try {
          // disable the submit button as we await tokenization and make a payment request.
          cardButton.disabled = true;
          const token = await tokenize(paymentMethod);
          const paymentResults = await createPayment(token);
          displayPaymentResults('SUCCESS');
  
          console.debug('Payment Success', paymentResults);
        } catch (e) {
          cardButton.disabled = false;
          displayPaymentResults('FAILURE');
          console.error(e.message);
        }
      }
  
      const cardButton = document.getElementById('card-button');
      cardButton.addEventListener('click', async function (event) {
        await handlePaymentMethodSubmission(event, card);
      });
  
      async function buildPaymentRequest(payments) {
        return Dispatch.getState('__.Shop.order.pricing')
          .pipe(
            map(e => wrappedRequest(e)),
            map(req => payments.paymentRequest(req)),
            take(1),
          ).toPromise()
      }
     
      async function initializeGooglePay(payments) {
        const paymentRequest = await buildPaymentRequest(payments)
     
        const googlePay = await payments.googlePay(paymentRequest);
        await googlePay.attach('#google-pay-button', {buttonSizeMode: 'fill', buttonType: 'long'});
     
        return googlePay;
      }
  
      async function initializeApplePay(payments) {
        const paymentRequest = await buildPaymentRequest(payments)
        const applePay = await payments.applePay(paymentRequest);
        // Note: You do not need to `attach` applePay.
        return applePay;
      }
     
  
      let googlePay;
      try {
        googlePay = await initializeGooglePay(payments);
      } catch (e) {
        console.error('Initializing Google Pay failed', e);
        // There are a number of reason why Google Pay may not be supported
        // (e.g. Browser Support, Device Support, Account). Therefore you
        // should handle initialization failures, while still loading other
        // applicable payment methods.
      }
  
      if (googlePay !== undefined) {
        const googlePayButton = document.getElementById('google-pay-button');
        googlePayButton.addEventListener('click', async function (event) {
          await handlePaymentMethodSubmission(event, googlePay);
        });
      }  
  
      let applePay;
      try {
        applePay = await initializeApplePay(payments);
      } catch (e) {
        console.error('Initializing Apple Pay failed', e);
        // There are a number of reason why Apple Pay may not be supported
        // (e.g. Browser Support, Device Support, Account). Therefore you should
        // handle
        // initialization failures, while still loading other applicable payment
        // methods.
      }
  
      if (applePay !== undefined) {
        const applePayButton = document.getElementById('apple-pay-button');
        applePayButton.addEventListener('click', async function (event) {
          await handlePaymentMethodSubmission(event, applePay);
        });
      }

  }
  shouldComponentUpdate() {
    return false
  }
  render() {
    return (


<div className='pt5 ph4 flex flex-column items-center' >
          <form id="payment-form" className='w-100'>
            <div id="card-container"></div>
            {/* <button id="card-button" className='w-100 bg-blue br3 white pa3 fw7' type="button">${(this.props?.__?.Shop?.order?.pricing?.totalRemaining/100).toFixed(2)}</button> */}


            <SubmitButton className=' w-100' 
              defaultChild={ props => (
                <div id='card-button' className='w-100 bg-blue br3 white pa3 fw7 f6 tc pointer '>
                    Pay ${ (this.props.totalRemaining/100).toFixed(2)}
                </div>
              )}
              loadingChild={ props => (
                <div key='loading2' className='w-100 ph4 flex justify-center'>
                  <div className='w2'>
                    <img src={loading} />
                  </div>
                </div>
              )}
              errorChild={ props => (
                <div key='error2' className='pl3 f5 fw7 flex justify-center red'> 
                </div>
              )}
              loadingStream={
                Dispatch.getAction('cart.pay')
                }
              successStream={
                Dispatch.getAction('Response.pay')
                  .pipe(
                    filter(e => _.isNil(e.errors) && (!_.isNil(e.data.pay))),
                  )
                }
              errorStream={
                Dispatch.getAction('Response.pay')
                  .pipe(
                    filter(e => !_.isNil(e.errors) || (_.isNil(e.data.pay))),
                  )}
              />

<SubmitButton className='mt3 w-100' 
              defaultChild={ props => (
                  <div id="google-pay-button" className='w-100'></div>
              )}
              loadingChild={ props => (
                <div key='loading2' className='w-100 ph4 flex justify-center'>
                  <div className='w2'>
                    <img src={loading} />
                  </div>
                </div>
              )}
              errorChild={ props => (
                <div key='error2' className='pl3 f5 fw7 flex justify-center red'> 
                </div>
              )}
              loadingStream={
                Dispatch.getAction('cart.pay')
                }
              successStream={
                Dispatch.getAction('Response.pay')
                  .pipe(
                    filter(e => _.isNil(e.errors) && (!_.isNil(e.data.pay))),
                  )
                }
              errorStream={
                Dispatch.getAction('Response.pay')
                  .pipe(
                    filter(e => !_.isNil(e.errors) || (_.isNil(e.data.pay))),
                  )}
              />

          <SubmitButton className='mt3 w-100' 
              defaultChild={ props => (
                  <div id="apple-pay-button" style={{
                      height:'48px',
                      width: '100%',
                      display: 'inline-block',
                      WebkitAppearance: '-apple-pay-button',
                      ApplePayButtonType: 'plain',
                      ApplePayButtonStyle: 'black'
                    }}
                
                    ></div>
              )}
              loadingChild={ props => (
                <div key='loading2' className='w-100 ph4 flex justify-center'>
                  <div className='w2'>
                    <img src={loading} />
                  </div>
                </div>
              )}
              errorChild={ props => (
                <div key='error2' className='pl3 f5 fw7 flex justify-center red'> 
                </div>
              )}
              loadingStream={
                Dispatch.getAction('cart.pay')
                }
              successStream={
                Dispatch.getAction('Response.pay')
                  .pipe(
                    filter(e => _.isNil(e.errors) && (!_.isNil(e.data.pay))),
                  )
                }
              errorStream={
                Dispatch.getAction('Response.pay')
                  .pipe(
                    filter(e => !_.isNil(e.errors) || (_.isNil(e.data.pay))),
                  )}
              />
              </form>
</div>
  )}
}


class PaymentPage extends React.Component {

  constructor(props) {
    super(props)
 
  }



  render() {
    const shop = this.props.__ && this.props.__.Shop ? this.props.__.Shop : {}
    const primaryColor = shop.primaryColor
    const secondaryColor = shop.secondaryColor
    const checkoutTerms = shop.checkoutTerms
    const total = shop.order?.pricing?.total
    const loadingView = <div className="sq-wallet-loading"></div>
    const unavailableView = <div></div>
    const totalRemaining = shop.order?.pricing?.totalRemaining



    return(
      <div className='w-100 pb4'>
        <ModalNavigator {...this.props} _title='Payment' _action={e => Dispatch.nextAction('__.Modal.open', { type: 'customerDetails' })} />

        <div className='flex flex-column'>
                 <div className=' pb1 mh4 f4 fw7 black bb b--light-gray'>Details</div>
                 <div className='pt3 ph4 fw4 flex flex-column'>
                   <div className=''><span className='fw6'>Name: </span>{this.props.__?.Shop?.customer?.givenName} {this.props.__?.Shop?.customer?.familyName}</div>
                   {/* <div className=''>Last: {this.props.__?.Shop?.customer?.familyName}</div> */}
                   <div className='pt1'><span className='fw6'>Email: </span> {this.props.__?.Shop?.customer?.emailAddress}</div>
                   <div className='pt1'><span className='fw6'>Phone: </span> {this.props.__?.Shop?.customer?.phoneNumber}</div>                   
                   <div className='pt3 fw5'><span className='fw7'>Total: </span> ${(total/100).toFixed(2)}</div>
                 </div>
               </div>
                 <div className='pt4 pb1 mh4 f4 fw7 black bb b--light-gray'>Ready Time</div>
                 {/* FIXME This should probably include all the fullfillments (for if people use the ASAP option) */}
                 <div className='pt3 ph4 w-100 f5 fw6'>{this.props.__.Shop?.fulfillment?.bookings && moment(this.props.__.Shop.fulfillment?.bookings).format('dddd Do h:mm a')}</div>

      <Payment totalRemaining={totalRemaining}/>

        </div>

    )
  }
}

export default PaymentPage