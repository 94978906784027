import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Shop from './pages/Shop'

import {
  Frontpage,
  PrivacyPolicy,
  TermsOfService,
  Error404,
  JobPage,
} from './pages';

export default (props) => (
  <Switch>
    <Route exact path="/" component={() => <Frontpage {...props} />} />
    <Route exact path="/jobs" component={() => <JobPage {...props} />} />


    <Route exact path="/privacy-policy" component={() => <PrivacyPolicy {...props} />} />
    <Route exact path="/terms-of-service" component={() => <TermsOfService {...props} />} />
    <Route exact path="/shop" component={() => <Shop {...props} />} />

    <Route component={() => <Error404 {...props} />} />
  </Switch>
);
