import { gql } from '@apollo/client';
import { Dispatch } from '@Corim/architecture-frontend'
import { map, filter } from 'rxjs/operators'

import _ from 'lodash'

export default () => {
  const GET_MODIFIERSLIST = gql`
  query modifierList {
    modifierList
  }
  `;

  Dispatch.nextAction('Request.modifierList', { type: 'query', query: GET_MODIFIERSLIST, variables: { }, poll: process.env.REACT_APP_POLL_INTERVAL,  retry: process.env.REACT_APP_RETRY_INTERVAL })

  Dispatch.getAction('Response.modifierList')
    .pipe(
      filter(e => _.isNil(e.errors)),
      map(e => e.data.modifierList),
      map(e => e.objects)
    )
    .subscribe(e => Dispatch.nextState('__.Shop.modifierList', e))
}