import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, from } from '@apollo/client';
//import { setContext } from 'apollo-link-context';
//import { createUploadLink } from 'apollo-upload-client'

// import { Auth } from 'aws-amplify'

// console.log(await Auth.currentSession())
// MAYBE NEEDS /graphql???
const httpLink = new HttpLink({ uri: (process.env.NODE_ENV === 'production') ? process.env.REACT_APP_PROD_API : process.env.REACT_APP_DEV_API });

const authMiddleware = new ApolloLink(async (operation, forward) => {
  // Using Auth.currentSession refreshes the access token
  const token = localStorage.getItem('token')//(await Auth.currentSession()).getAccessToken().getJwtToken()
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token || '',
    }
  }));

  return forward(operation);
})

//const uploadLink = createUploadLink({uri: (process.env.NODE_ENV === 'production') ? process.env.REACT_APP_PROD_API : process.env.REACT_APP_DEV_API })

const link = from([
  authMiddleware,
  // This has to be before httpLink
  //uploadLink,
  httpLink,
  //uploadLink,
])



const client = new ApolloClient({
  // Provide required constructor fields
  cache: new InMemoryCache(),
  //uri: (process.env.NODE_ENV === 'production') ? process.env.REACT_APP_PROD_API : process.env.REACT_APP_DEV_API,

  // Provide some optional constructor fields
  // These fields causing CORS error
  // name: 'react-web-client',
  // version: '1.3',
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  link,
});


export default client 