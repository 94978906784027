import { gql } from '@apollo/client';
import { Dispatch } from '@Corim/architecture-frontend'
import { map, distinctUntilChanged, startWith, filter, tap } from 'rxjs/operators'
import { combineLatest } from 'rxjs'

import _ from 'lodash'

export default () => {

  const GET_INVENTORY = gql`
    query batchRetrieveInventoryCounts($catalog_object_ids: [String]) {
      batchRetrieveInventoryCounts(catalog_object_ids: $catalog_object_ids)
    }
  `;

  // Inventory
  Dispatch.getState('__.Shop.items')
    .pipe(
      map(items => items.map(item => _.filter(item.item_data?.variations, variation => variation?.item_variation_data?.location_overrides?.[0]?.track_inventory))),
      map(items => items.reduce((acc,next) => ([...acc, ...next.map(variation => variation.id)]), [])),
    )
    .subscribe(e => Dispatch.nextAction('Request.inventory', { type: 'query', query: GET_INVENTORY, variables: { catalog_object_ids: e }, poll:process.env.REACT_APP_POLL_INTERVAL, retry: process.env.REACT_APP_RETRY_INTERVAL }))

  Dispatch.getAction('Response.inventory')
  .pipe(
    filter(e => _.isNil(e.errors)),
    map(e => e.data.batchRetrieveInventoryCounts),
    map(e => e.counts),

    // Don't update while polling unless something has changed
    distinctUntilChanged((a, b) => _.isEqual(a, b)),
  )
  .subscribe(e => Dispatch.nextState('__.Shop.inventory', e))
}