import React from 'react'
import BeforeAfterSlider from 'react-before-after-slider'
import ContainerDimensions from 'react-container-dimensions'

import imgOld from '../../media/images/old.jpg'
import imgNew from '../../media/images/new.jpg'

const img = {
  id: 1,
  before: imgOld,
  after: imgNew,
  height: 75,
}

const ImageSlider = () => (
  <div
    style={{
      zIndex: 1,
      overflow: 'hidden',
      userSelect: 'none',
      borderBottom: '8px solid #415C74',
    }}
  >
    <ContainerDimensions>
      {({ width, height }) => (
        <BeforeAfterSlider
          key={img.id * 10}
          before={img.before}
          after={img.after}
          width={width}
          height={(width / 2) + img.height}
          defaultProgress={0.893}
        />
      )}
    </ContainerDimensions>
  </div>
)

export default ImageSlider
