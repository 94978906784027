import { gql } from '@apollo/client';
import { Dispatch } from '@Corim/architecture-frontend'
import { map, distinctUntilChanged, startWith, filter } from 'rxjs/operators'
import { combineLatest } from 'rxjs'

import _ from 'lodash'

export default () => {
  const GET_ITEMS = gql`
    query items {
      items 
    }
  `;

  Dispatch.nextAction('Request.items', { type: 'query', query: GET_ITEMS, variables: { }, poll: process.env.REACT_APP_POLL_INTERVAL, retry: process.env.REACT_APP_RETRY_INTERVAL })

  Dispatch.getAction('Request.items')
    .subscribe(e => Dispatch.nextState('__.Shop.loading.items', true))

  Dispatch.getAction('Response.items')
    .subscribe(e => Dispatch.nextState('__.Shop.loading.items', false))

  combineLatest(Dispatch.getAction('Response.items')
    .pipe(
      filter(e => _.isNil(e.errors)),
      map(e => e.data.items),
      map(e => e.objects),
      // Don't update while polling unless something has changed
      distinctUntilChanged((a, b) => _.isEqual(a, b)),
      // Remove hidden Items
      //map(items => _.filter(items, item => !(item.custom_attribute_values && item.custom_attribute_values.hidden && item.custom_attribute_values.hidden.boolean_value === true))),
      // Remove hidden Variations
      map(items => items.map(item => ({...item, item_data: { ...item.item_data, variations: _.filter(item.item_data.variations, variation => !(variation.custom_attribute_values && variation.custom_attribute_values.hidden && variation.custom_attribute_values.hidden.boolean_value === true )) }}))),
      // Remove items with no variations
      map(items => _.filter(items, item => item.item_data.variations.length > 0)),
      // Remove variations without a price
      map(items => items.map(item => ({...item, item_data: { ...item.item_data, variations: _.filter(item.item_data.variations, variation =>  variation.item_variation_data?.price_money?.amount ? true : false) }}))),
      // Flatten Variations
      map(items => items.map(item => ({...item, variations: item.item_data.variations.map(variation => ({...variation, ...variation.item_variation_data})), }))),
      // Parse Description to add return characters
      //map(items => items.map(item => ({...item, item_data: {...item.item_data, description: item.item_data.description && item.item_data.description.replace(/(\r\n|\n|\r)/gm, ",&#10;") }}))),
      // Order Alphabetically
      map(items => _.orderBy(items, item => {
        return item.item_data.name
      })),
    ),
    Dispatch.getState('__.Shop.inventory')
      .pipe(
        startWith([])
      ),
    Dispatch.getState('__.Shop.modifierList'),
    Dispatch.getState('__.Shop.modifiers')
  )
    .pipe(
      // Set stock status of item variations
      map(([items, inventory, modifier_list, modifiers]) => items.map(item => {
        const variations = item.variations.map(variation => {
          const status = _.find(inventory, inv => inv.catalog_object_id === variation.id)
          return status ? ({...variation, quantity: status.quantity, stock_state: (status.quantity <= 0) ? 'Sold Out' : ((status.quantity >= 5) || (_.isNil(status.quantity))) ? 'In Stock' : 'Low Stock' }) : ({ ...variation })
        })

        // Map modifier data to items
        const mod_list_info = item.item_data?.modifier_list_info ?? []

        const newModifiers = mod_list_info.map(entry => {
          const modList = _.find(modifier_list, mod_list => mod_list.id === entry.modifier_list_id)
          return {
            max: entry.max_selected_modifiers,
            min: entry.min_selected_modifiers,
            enabled: entry.enabled,
            modifierList: { id: modList.id, name: modList.modifier_list_data.name, type: modList.modifier_list_data.selection_type },
            modifiers: _.filter(modifiers, mod => mod.modifier_data.modifier_list_id === entry.modifier_list_id)
              // Simplify Modifier
              ?.map(mod => ({ id: mod.id, name: mod.modifier_data.name, defaultOn: mod.defaultOn, amount: mod.modifier_data?.price_money?.amount }))
              // Add any custom overrides
              ?.map(mod => ({...mod, defaultOn: (_.find(entry.modifier_overrides, override => override.modifier_id === mod.id)?.on_by_default ?? mod.defaultOn)}))
          }
        })

        return {...item, variations, modifiers: newModifiers}
      })),
      // Set item sold out status if all variations are sold out
      map(items => items.map(item => ({...item, sold_out: item.variations.reduce((acc, next) => (!acc || next.stock_state !== 'Sold Out') ? false : true, true )}))),
    )
    .subscribe(e => Dispatch.nextState('__.Shop.items', e))
}

