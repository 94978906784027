import React from 'react';
import { withRouter } from 'react-router-dom';

import imgSplash from '../../media/images/cafesplash.png';

class MenuCafe extends React.Component {
  state = {
    height: 0
  }

  toggle = () => {
    const { height } = this.state;
    this.setState({
      height: height === 0 ? 'auto' : 0,
    });
  }

  render() {
    return (
      <div
        style={{
          display: 'block',
          background: 'rgb(39, 39, 39)',
          width: '100%',
          border: '6px solid rgb(89, 121, 145)',
          borderBottom: 'none',
          color: 'rgb(206, 206, 206)',
          margin: 'auto',
        }}
      >

        <div className="row" style={{ margin: '4px auto 0px auto', padding: '13px 0 5px 0', maxWidth: 430 }}>
          <h4
            style={{
              textAlign: 'center',
              color: '#CECECE',
              width: '100%',
              background: '#272727',
              fontSize: 28,
              margin: 0,
              paddingTop: 0,
              fontWeight: 400,
              top: 83,
              textTransform: 'uppercase',
            }}
          >
            Cafe Menu
            <span style={{ color: '#828282', textTransform: 'lowercase', fontSize: '1.2rem' }}>
              &nbsp; at a glance
            </span>
          </h4>

          <div style={{ textAlign: 'center', marginTop: 15, marginBottom: 10 }}>
            <img src={imgSplash} alt="" style={{ width: '95%', margin: 'auto' }} />
          </div>

        </div>
      </div>
    )
  }
}

export default withRouter(MenuCafe)
