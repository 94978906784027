import React from 'react';
import { Link } from 'react-router-dom';

import getBusinessHours from '../../utils';

import imgLogo from '../../media/images/logo.svg';
import imgLogoLight from '../../media/images/logoLight.svg';

export default () => {
  const isOpen = getBusinessHours.isCurrentlyOpen();
  return (
    <header
      style={{
        borderTopColor: isOpen ? '#9FC65D' : '#5c6d3e',
        background: isOpen ? '#fff' : '#1A1D23',
      }}
    >
      <Link to="/">
        <img
          src={isOpen ? imgLogo : imgLogoLight}
          alt="Welcome to Cafe 2894 On Main"
        />
      </Link>
    </header>
  )
};
