import _ from 'lodash';
import React from 'react';
//import PropTypes from 'prop-types';

import Dispatch from './Dispatch'


/*
 * To Add BaseRxComponent functionality to a component, use it
 * as a wrapper.
 *
 * Example:
 *
 * <RxBaseComponent
 *   store={combinedObservable}
 *   Component={ComponentClass}
 *   loading=false
 *  />
*/

export default class RxBaseComponent extends React.Component {

  // static propTypes = {
  //   //TODO: Add this someday
  //   //store: PropTypes.Observable
  //   // loading: PropTypes.bool,
  //   // loader: PropTypes.func,
  //   Component: PropTypes.oneOfType([
  //     PropTypes.string,
  //     PropTypes.object,
  //     PropTypes.func
  //   ]).isRequired
  // }

  // static defaultProps = {
  //   // loading: true,
  //   // loader: () => "test"
  // };

  constructor(props) {
    super(props);
    //this.loaded = false;
  }

  componentWillMount() {
    if (_.isNil(this.props.store)) {
      this.Store = Dispatch.getState()
        .subscribe((e) => {
          // Does this actually help? Or does react still do a full dif and cause a massive delay?
          //const { Textbox, ...storeValues } = e
          //this.loaded = true;
          this.setState(e);
        });
    } else {
      this.Store = this.props.store
        .subscribe((e) => {
          //const { Textbox, ...storeValues } = e
          //this.loaded = true;
          this.setState(e);
        });
    }
  }

  componentWillUnmount() {
    this.Store.unsubscribe();
  }

  render() {
    // If component is loading show a spinner instead of rendering component
    // If component is loading show a spinner instead of rendering component
    // if (this.props.loading && this.loaded === false) {
    //   //const CustomLoader = this.props.loader;
    //   return (
    //     null
    //   );
    // }

    //const propsWithReset = _.merge({}, this.props, { reset: this.reset });
    const { Component, store, ...withoutComponent } = this.props
    const combinedProps = _.merge({}, { Dispatch }, withoutComponent, this.state);

    const ComponentToRender = Component;
    return <ComponentToRender {...combinedProps} />
  }
}
