import _, { isPlainObject } from 'lodash'
import { map, share } from 'rxjs/operators'
import { filter, observeOn, delay } from 'rxjs/operators'
import { queueScheduler, Observable } from 'rxjs'

export default (isProduction, log) => {
  let dispatch;

  const update$ = Observable.create(observer => {
    dispatch = action => observer.next(action)
  })
    .pipe(
      // Forces every value to get rendered before next value is calculated
      observeOn(queueScheduler),
      // Delay allows state to update in between the dispatches. 
      // If dispatch is able to continuously dispatch, a bug can occur where the lateststate does not update in between which can causing overriding of state values
      delay(1),
      share(),
    )

  update$.subscribe((event) => {
    if (!isProduction && log) {
      console.log(
        `%cDispatch:`,
        'background: green; color: white'
      )
      console.log(event)
    }
  });

  const checkId = (id, updateId) => {
    if (!_.isNil(id)) {
      const idTags = id.split('.')
      const updateTags = updateId.split('.')
      return idTags.reduce((acc, next, index) =>
        acc ? updateTags[index] === next || next === '*' : false, true)
    }
    else {
      return EMTPY
    }
  }

  // export const getId = (id) => update$
  //   .pipe(
  //     filter(update => checkId(id, update.id)),
  //     map(e => e.id)
  //   )



  // export const getActionWithMeta = (id, action) => update$
  // .pipe(
  //   filter(update => {
  //     const exists = checkId(id, update.id)
  //     return action ? exists && update.action === action : exists
  //   }
  //   )
  // )


  const getAction = (listener) => {
    if (!isProduction && _.isNil(listener)) {
      console.log("ID IS UNDEFINED IN GETACTION2")
    }
    const id = listener ? listener : ''
    const starred = id.substring(id.length-2, id.length) === '.*'
    return update$
      .pipe(
        filter(update => {
          if (starred) {
            const unstarredId = id.replace('.*', '')
            const listenerSubstring = unstarredId.split('.')
            const updateSubstring = update.id.split('.')
            return listenerSubstring.reduce((acc, next, index) => acc && updateSubstring[index] === next, true)
          }
          else {
            return id === update.id
          }
        }),
        map(update => {
          if (!starred) {
            return update.value
          } 
          else 
          {
            const unstarredId = id.replace('.*', '')
            let updatedId = update.id.replace(unstarredId, '')
            if (updatedId.substring(0, 1) === '.') {
              updatedId = updatedId.substring(1, updatedId.length)
            }
            return {id: updatedId, value: update.value}
          }
        })
      )
    }

  const nextAction = (id, value) =>
    dispatch({id: id, value: value})

  return {
    nextAction,
    getAction,
  }
}