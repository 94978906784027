import React from 'react';
import { Header, Footer } from '../blocks';

export default (props) => {
  const { children } = props;
  return (
    <div className="min-vh-100 webpage-wrapper">
      <Header {...props} />
      {children}
      <Footer />
    </div>
  );
}
