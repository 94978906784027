import React from 'react';
import { Dispatch, Textbox, SubmitButton, MultiSelect } from '@Corim/architecture-frontend'
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faParachuteBox, faArrowAltCircleLeft, faBars, faFish, faPlus} from '@fortawesome/free-solid-svg-icons'
import { tap, map, startWith, mergeMap, switchMap, filter, delay } from 'rxjs/operators'
import { merge, combineLatest, of } from 'rxjs'
import loading from '../../media/loading.svg'
import ModalNavigator from './ModalNavigator';
import moment from 'moment'

{/* <MultiSelect options={options} id={'dropspot'} isMultiSelect={false} defaultValue={dropspot ? {[dropspot]: true} : {} }/> */}

const ModifierList = (props) => {
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  const primaryColor = shop.primaryColor
  const secondaryColor = shop.secondaryColor
  const list = props.__list ? props.__list : {}
  const listData = list?.modifierList ?? {}
  const modifierData = list?.modifiers ?? {}
  const name = listData?.name
  const multiSelect = listData?.type === 'SINGLE' ? false : true
  const listId = listData.id
  const modifiers = list?.modifiers ?? []
  const unselectable = (list.min === 0)
  const defaultSelectedModifiers = modifiers.reduce((acc, next) => next?.defaultOn ? { ...acc, [next.id]: true} : {...acc}, {} )
  const options = modifiers.map(option => {
    const MultiButton = (props) => {
      const selected = props.selected
      const selectedClass = selected ? 'bg-' + primaryColor + ' ' + secondaryColor : ''
      const money = option?.amount ? '+$' + (option?.amount/100).toFixed(2) : ''
      return (
        <div className={'mv1 mr1 pa3 f5 br2 b--moon-gray ba pointer ' + selectedClass }>
          <span className='fw7'>{option.name}</span> {money}
        </div>
      )
    }
    return {...option, component: MultiButton}
  })
  return (
    <div key={listId}>
      <div className='pt4 f4 fw7'>{name}</div>
      <div className='pt1 f6 fw4'>{multiSelect ? '(select multiple)' : '(select one)'}</div>
      <div className='pt2'><MultiSelect options={options} id={'cart.modifiers.' + listId} isMultiSelect={multiSelect} defaultValue={defaultSelectedModifiers} unselectable={unselectable} /></div>
      {/* <div className='pt3 ph4'>{modifiers.map(mod => <Modifier z_mod={mod} {...props}/> )}</div> */}
    </div>
  )
}

const Variations = (props) => {
  const item = props.z_item
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  const primaryColor = shop.primaryColor
  const secondaryColor = shop.secondaryColor
  //const name = item.item_data.name
  const variations = item.variations
  const id = item.id
  const options = variations.map(option => {
    const MultiButton = (props) => {
      const selected = props.selected
      const selectedClass = selected ? 'bg-' + primaryColor + ' ' + secondaryColor : ''
      const outOfStockClass = ' bg-dark-gray white ' 
      return (
            <div className={'mv1 mr1 pa3 f5 br2 b--moon-gray ba pointer ' + (option.stock_state === 'Sold Out' ? outOfStockClass : selectedClass) }>
              <span className='fw7'>{option.name}</span> +${(option?.price_money?.amount/100).toFixed(2)}
              <div className='pt1 f7 fw8'>
                {option.stock_state === 'Low Stock' ? <div >{option.quantity} In Stock</div> : <div >{option.stock_state}</div>}
              </div>
            </div>
      )
    }
    return {...option, component: MultiButton}
  })
  return (
    <div key={id}>
      <div className='pt4 f4 fw7'>Variations</div>
      <div className='pt1 f6 fw4'>(select one)</div>
      <div className='pt2'><MultiSelect options={options} id={'cart.variations'} isMultiSelect={false} defaultValue={{[variations[0].id]: true}}/></div>
      {/* <div className='pt3 ph4'>{modifiers.map(mod => <Modifier z_mod={mod} {...props}/> )}</div> */}
    </div>
  )
}

export default (props) => {
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  const primaryColor = shop.primaryColor
  const secondaryColor = shop.secondaryColor
  const isClosed = shop.isClosed === 'true'

  const itemId = shop && shop.item && shop.item.selected
  const item = _.find(shop.items, i => i.id === itemId)

  const image = _.find(shop.images, i => i.id === item.image_id)
  const url = image ? image.image_data.url : ''
  const variations = item.variations

  const nextChunk = shop.item && shop.item && shop.item.nextChunk && shop.item.nextChunk
  const nextAvailability = (nextChunk !== 'loading') && shop.item.nextChunk
  const nextChunkLoading = (nextChunk === 'loading')

  // function nextWeekday (day, weekday) {
  //   const current = day.day()
  //   const days = (7 + weekday - current) % 7
  //   return day.clone().add(days, 'd')
  // }
  
  //console.log(leadTime)
  // Should be returned from prisma
  //const nextAvailability = nextWeekday(moment(), 5).format('dddd Do')
  // Should be returned from prisma
  //const nextNextAvailability = leadTime && nextWeekday(nextWeekday(moment(), 5).add(1, 'days'), 5).format('dddd Do')

  //const pickupTime = leadTime && nextWeekday(moment().startOf('day'), 5).add(18, 'hours').subtract(leadTime, 'minutes')
  // const available = leadTime && 'Earliest Pickup: ' + (pickupTime.fromNow().includes('ago') ? nextNextAvailability : nextAvailability)
  //const remainingTime = leadTime && 'Order within ' + pickupTime.fromNow().replace('in ', '')
  //const orderTime = leadTime && (pickupTime.fromNow().includes('ago') ? 'Earlist availability: ' + nextNextAvailability : 'Order within ' + pickupTime.fromNow() + ' for Friday delivery')

  
  const pickupTime = nextAvailability && moment(nextAvailability.endTime)
  const orderByTime = nextAvailability && moment(nextAvailability.orderBy)
  const available = nextAvailability && ('Earliest Pickup: ' + pickupTime.format('dddd Do h:mm a'))
  const remainingTime = nextAvailability && (orderByTime.fromNow() !== 'a few seconds ago') && ('Order within ' + orderByTime.fromNow().replace('in ', '') + ' for earliest pickup')
  const displayRemainingTime = shop?.displayRemainingTime

  const modifierList = item?.modifiers ?? []
  const amount = variations.length > 1 ? 'Variable Cost' : variations[0]?.item_variation_data?.price_money?.amount ? 
      '$' + (variations[0]?.item_variation_data?.price_money?.amount/100).toFixed(2)
      :
      'No Price'
  const options = [1,2,3,4,5].map(option => {
    const MultiButton = (props) => {
      const selected = props.selected
      const selectedClass = selected ? 'bg-' + primaryColor + ' ' + secondaryColor : ''
      return (
        <div className={'mv1 mr1 pa3 ph4 f5 br2 b--moon-gray ba pointer ' + selectedClass }>
          <span className='fw7'>{option}</span>
        </div>
      )
    }
    return {id: option, component: MultiButton}
  })

  const selectedVariation = shop && shop.item && shop.item.variation
  const variation = _.find(variations, variation => variation.id === selectedVariation)
  const stockStatus = variation && (!variation?.stock_state || variation?.stock_state === 'In Stock') ? null : (variation?.stock_state === 'Low Stock') ? ('- ' + variation?.quantity + ' In Stock') : '- Sold Out'

  return (
    <div key={item.id} className={'w-100 dark-gray flex bg-white'}>
      <div className='w-100 flex flex-column tl'>
        <ModalNavigator {...props} _title='Item Details' _action={e => Dispatch.nextAction('__.Modal.close')} />
        {url ? 
          <div className='ph4'><img style={{maxHeight:'100%', objectFit: 'cover'}} className='w-100' src={url} /></div> : null }
        
        <div className={ 'ph4 f3 fw8 ' + primaryColor + (url ? ' pt3 ' : ' pt2 ') }>{item.item_data.name}</div>
        {/* The varations will be an issue with pricing */}
        <div className='ph4 pt1 f4 fw5'>{amount}</div>
        <div className='pt4'></div>
        { stockStatus && <div className='pb1 ph4 f7 fw8'>{stockStatus}</div> }
        { nextChunkLoading ?
          <div className='pb1 ph4 f7 fw8'><img src={loading} /> </div>
          :
          <>
            {(stockStatus !== '- Sold Out') ?
              <>
              {available ? <div className='pb1 ph4 f7 fw8'>- {available}</div> : null}
              {displayRemainingTime && remainingTime && <div className='ph4 f7 fw8 red'>- {remainingTime}</div>}
              </>
              :
              null
            } 
          </>
        }
        <div className='ph4 f5 fw7 pt4'>Description:</div>
        <div className='ph4 f5 fw4 pt2' style={{whiteSpace: 'pre-line'}}>{item.item_data.description}</div>
        <div className='mt4 mh4 bt b--moon-gray'>
          {variations.length > 1 ? <Variations {...props} z_item={item} /> : null}
          {modifierList.map(list => <ModifierList key={list.modifierList.id} __list={list} {...props} />)}
        </div>
        <div className='ph4 pt4 f4 fw7'>Notes:</div>
        <div className=' mt1 mh4 ba br2 b--moon-gray'>
          <Textbox
            className='w-100 pa3 bn br2 dark-gray fw4 '
            id='cart.note'
            placeholder=''
            style={{ outline: 'none', borderStyle: 'solid' }}
            type='text'
            defaultValue={''}
        
          />
        </div>
        <div className='ph4 pt4 fw7 f4'>Quantity</div>
        <div className='ph4 pt1 f6 fw4'>(select one)</div>
        <div className='ph4 pt2'><MultiSelect options={options} id={'cart.quantity'} isMultiSelect={false} defaultValue={{1: true}}/></div>
        <div className='pt4 pb5 flex justify-center items-center f5 fw5'>
          <SubmitButton id={'addToCart'} className='flex items-center pt2' 
            responseId='getCart'
            defaultChild={ props => (
              <>
              { isClosed ?
                <div className={ 'mt2 ph5 pv3 br2 f4 fw5 pointer bg-gray white' }>
                    Currently Closed
                </div>
                :
                <>
                { (stockStatus === '- Sold Out') ?
                  <div 
                    className={ 'mt2 ph5 pv3 br2 f4 fw5 pointer bg-gray white'}>
                      Out Of Stock
                  </div>
                  :
                  <div 
                    className={ 'mt2 ph5 pv3 br2 f4 fw5 pointer bg-' + primaryColor + ' ' + secondaryColor }
                    onClick={e => Dispatch.nextAction('order.addItem', item.item_data.variations[0].id)}>
                      Add To Cart
                  </div>
                }
                </>
             }
             </>
            )}
            loadingChild={ props => (
              <div className='w-100 flex justify-between'>
                <div></div>
                <div className='w2'>
                  <img src={loading} />
                </div>
              </div>
            )}
            errorChild={ props => (
              <div className='pl3 f7 fw4 red tr pointer'>Error</div>
            )}
            loadingStream={
              merge(Dispatch.getAction('Request.addItem'), Dispatch.getAction('Request.updateQuantity'))
              }
            successStream={
              merge(Dispatch.getAction('Response.addItem'), Dispatch.getAction('Response.updateQuantity'))
                .pipe(
                  filter(e => _.isNil(e.errors) && (!_.isNil(e.data.addItem))),
                )
              }
            errorStream={
              Dispatch.getAction('Response.addItem')
                .pipe(
                  filter(e => !_.isNil(e.errors) || (_.isNil(e.data.addItem))),
                )}
          />
        </div>
        <div className='h3 h1-ns'></div>
      </div>
    </div>
  )
}