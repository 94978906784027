import moment from 'moment';

/**
 * A business needs to know the hours in which it operates.
 *
 * Import `getBusinessHours` and get access to:
 *   - isCurrentlyOpen() - bool on wether or not the cafe is currently open
 *   - todaysWeekInt() - todays week number. Zero-based (Sunday=0)
 *   - getDayMeta(int) - returns the given days business hour meta as an object
 *
 * Would like to consider https://schema.org/openingHours
 *
 */

const getDayMeta = weekInt => {
  const hFormat = 'hh:mm';
  const d = new Date();
  const dayList = {
    0: function () {  // Sunday
      return {
        id: 0,
        weekName: 'Sunday',
        whileClosedMsg: 'back at 7am',
        whileOpenMsg: 'closes at 3pm',
        startTime: moment('07:00', hFormat),
        endTime: moment('15:00', hFormat)
      };
    },
    1: function () {  // Monday
      return {
        id: 1,
        weekName: 'Monday',
        whileClosedMsg: 'back at 6am',
        whileOpenMsg: 'closes at 3pm',
        startTime: moment('06:00', hFormat),
        endTime: moment('15:00', hFormat)
      };
    },
    2: function () {  // Tuesday
      return {
        id: 2,
        weekName: 'Tuesday',
        whileClosedMsg: 'back at 6am',
        whileOpenMsg: 'closes at 3pm',
        startTime: moment('06:00', hFormat),
        endTime: moment('15:00', hFormat)
      };
    },
    3: function () {  // Wednesday
      return {
        id: 3,
        weekName: 'Wednesday',
        whileClosedMsg: 'back at 6am',
        whileOpenMsg: 'closes at 3pm',
        startTime: moment('06:00', hFormat),
        endTime: moment('15:00', hFormat)
      };
    },
    4: function () {  // Thursday
      return {
        id: 4,
        weekName: 'Thursday',
        whileClosedMsg: 'back at 6am',
        whileOpenMsg: 'closes at 3pm',
        startTime: moment('06:00', hFormat),
        endTime: moment('15:00', hFormat)
      };
    },
    5: function () {  // Friday
      return {
        id: 5,
        weekName: 'Friday',
        whileClosedMsg: 'back at 7am',
        whileOpenMsg: 'closes at 3pm',
        startTime: moment('06:00', hFormat),
        endTime: moment('15:00', hFormat)
      };
    },
    6: function () {  // Saturday
      return {
        id: 6,
        weekName: 'Saturday',
        whileClosedMsg: 'back at 7am',
        whileOpenMsg: 'closes at 3pm',
        startTime: moment('07:00', hFormat),
        endTime: moment('15:00', hFormat)
      };
    }
  };
  if (weekInt === parseInt(weekInt, 10)) {
    return dayList[weekInt]()
  } else {
    return dayList[d.getDay()]()
  }
}

const todaysWeekInt = () => {
  const d = new Date();
  return d.getDay()
};

const isCurrentlyOpen = () => moment().isBetween(
  getDayMeta(todaysWeekInt()).startTime,
  getDayMeta(todaysWeekInt()).endTime
) && true;

const getBusinessHours = () => ({
  isCurrentlyOpen,
  todaysWeekInt,
  getDayMeta,
});

export default getBusinessHours();
