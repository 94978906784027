import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const suggestions = [
  {
    id: 1,
    description: 'The barista\'s suggestions were spot on.',
    name: 'Danielle M.',
    from: 'East Troy, WI',
    stars: 5,
  },
  {
    id: 2,
    description: 'The most amazing meatball sandwich.',
    name: 'Craig R.',
    from: 'McHenry, IL',
    stars: 4,
  },
  {
    id: 3,
    description: 'Vince makes an outstanding cappuccino!',
    name: 'Britney L.',
    from: 'Delafield, WI',
    stars: 5,
  },
  {
    id: 4,
    description: 'The best place to meet a friend. Old and new.',
    name: 'Chas W.',
    from: 'San Francisco, CA',
    stars: 5,
  },
  {
    id: 5,
    description: 'Came in for a coffee. Left with ingredients.',
    name: 'Emily W.',
    from: 'Waukesha, WI',
    stars: 4,
  },
  {
    id: 6,
    description: 'Thanks for hosting our study group!',
    name: 'Mary H.',
    from: 'Delafield, WI',
    stars: 5,
  },
  {
    id: 7,
    description: 'My 3rd place.',
    name: 'Jake S.',
    from: 'Elkhorn, WI',
    stars: 5,
  },
]

const Stars = ({ stars }) => (
  <div
    style={{
      position: 'absolute',
      color: '#EFAA21',
      bottom: 25,
      left: 7,
    }}
  >
    {Array(stars).fill().map((x, index) => (
      <FontAwesomeIcon key={index} icon="star" fixedWidth />
    ))}
  </div>
)

const CustomerSuggestions = () => (
  <div style={{ marginTop: 18, marginBottom: 10, paddingRight: 0 }}>

    <div className="hscroll" style={{ padding: '0 0 20px 20px', }}>
      <ul
        className="flex quote-list"
        style={{ listStyleType: 'none', margin: 0, padding: 0, }}
      >
        {suggestions.map(item => (
          <li
            key={item.id}
            style={{
              marginTop: 10,
              position: 'relative',
              flex: '0 0 auto',
              marginRight: 25,
              minWidth: 230,
              maxWidth: 350,
              height: 90,
              paddingTop: 5,
              paddingLeft: 10,
              paddingRight: 10,
              background: '#F5F5F5',
              boxShadow: '0 2px 15px 0 rgba(0,0,0,0.09)',
              borderRadius: '9px',
            }}
          >
            <p style={{ fontSize: 14, fontStyle: 'italic', }}>
              {item.description}
            </p>

            <span
              style={{
                position: 'absolute',
                right: 20,
                bottom: 35,
                fontSize: 12,
                color: '#888888',
              }}
            >
              —
              {item.name}
            </span>

            <span
              style={{
                position: 'absolute',
                right: 20,
                bottom: 23,
                fontSize: 11,
                color: '#acacac',
              }}
            >
              {item.from}
            </span>

            <Stars stars={item.stars} />

          </li>
        ))}
      </ul>
    </div>

  </div>
)

export default CustomerSuggestions
