import { gql } from '@apollo/client';
import { Dispatch } from '@Corim/architecture-frontend'
import { map, distinctUntilChanged, tap, switchMap, startWith, withLatestFrom, filter } from 'rxjs/operators'
import moment from 'moment'

import _ from 'lodash'

export default () => {
  const GET_NEXT_OPEN_ITEM_CHUNK = gql`
    query getNextOpenItemChunk($id: String! $type: String! $time: String!) {
      getNextOpenItemChunk(id: $id type: $type time: $time) {
        id
        startTime
        endTime
        orderBy
      }
    }
  `;

  // On item select, update state
  Dispatch.getAction('__.Shop.item.select')
    .subscribe(e => Dispatch.nextState('__.Shop.item.selected', e))

  // On item select, get the current book time
  Dispatch.getState('__.Shop.item.selected')
    .subscribe(e => Dispatch.nextAction('Request.itemBookTime', { type: 'query', query: GET_NEXT_OPEN_ITEM_CHUNK, variables: { id: e, type: 'item', time: moment().toISOString()}, poll: process.env.REACT_APP_AVAILABILITY_POLL_INTERVAL, retry: process.env.REACT_APP_RETRY_INTERVAL }))

  // On request of book time, set state to loading, then wait for response
  Dispatch.getState('__.Shop.item.selected')
    .pipe(
      switchMap(e => Dispatch.getAction('Response.itemBookTime')
        .pipe(
          filter(e => _.isNil(e.errors)),
          map(e => e.data.getNextOpenItemChunk),
          startWith('loading')
        )
      ),
      distinctUntilChanged((a, b) => _.isEqual(a, b)),
      startWith('loading')
    )
    .subscribe(e => Dispatch.nextState('__.Shop.item.nextChunk', e))

  // Set selected variation according to the selected item / multiselect selection
  Dispatch.getState('__.Shop.item.selected')
    .pipe(
      withLatestFrom(Dispatch.getState('__.Shop.items')),
      switchMap(([selectedItem, items]) => Dispatch.getState('MultiSelect.cart.variations')
        .pipe(
          map(variations => _.findKey(variations, variation => variation === true)),
          startWith(_.find(items, item => item.id === selectedItem).variations?.[0]?.id)
        )
    ),
    )
    .subscribe(e => Dispatch.nextState('__.Shop.item.variation', e))
}