import { of, merge, Observable } from 'rxjs';
import { map, distinctUntilChanged, switchMap, debounceTime,
  startWith, publishReplay, refCount, mergeMap } from 'rxjs/operators';
import _ from 'lodash'

import history from '../../architecture/history'

import Dispatch from '../../architecture/Dispatch'
import queryString from 'query-string'

export default (redirect, searchParse, queryParams) => {

  // // Permissions
  // const signinRequired = [
  //   'cart',
  //   'getquote',
  //   'checkout',
  // ]

  // const cartItemsRequired = [
  //   'checkout',
  // ]

  // // Add query params
  // const queryParams = {
  //   detail: {
  //     product: (variables) => variables.products.selected 
  //   },
  //   getquote: {
  //     product: (variables) => variables.products.selected
  //   }
  // }

  // const searchParse = {
  //   product: (value) => Dispatch.nextAction('products', 'select', value)
  // }

  // // Redirect Function
  // const redirect = (url, user, cart) => {
  //   let redirectUrl = url.substring(1)
  //   if (_.includes(signinRequired, redirectUrl)){
  //     redirectUrl = _.isNil(user) ? 'auth' : redirectUrl
  //   }
  //   if (_.includes(cartItemsRequired, redirectUrl)){
  //     redirectUrl = cart && cart.cartItems && cart.cartItems.length > 0 ? 
  //       redirectUrl : 'shop'
  //   }
  //   return '/' + redirectUrl
  // }

  // Parse Function
  const parseSearch = (searchString) => {
    const search = queryString.parse(searchString)
    Object.keys(search).forEach(key => _.isNil(searchParse[key]) ? null : searchParse[key](search[key]))
  }

  // Url Watcher
  const url$ = Observable.create(observer => {
    history.listen((location, action) => observer.next({ location, action }))
  })
  .pipe(
    distinctUntilChanged((a, b) => _.isEqual(a, b)),
    startWith({ location: history.location, action: "POP" }),
    publishReplay(1),
    refCount()
  )

  // Url Pusher
  const push$ = Dispatch.getAction('navigate')
    .pipe(
      map(e => ({ location: { pathname: '/' + e }, action: "PUSH" }))
    )

  merge(push$, url$)
    .pipe(
      switchMap(e => of(e)
        .pipe(
          mergeMap(() => Dispatch.getState()),
          // Necessary to prevent numerous updates on URL bar. We only care about final value.
          debounceTime(1),
          map(state => ({ url: e, state })),
          map(f => ({ ...f, url: 
            { ...f.url, location: 
              { ...f.url.location, 
                pathname: redirect(f.url.location.pathname, 
                  f.state) 
              } 
            } 
          })),
        )
      ),
    )
    .subscribe(e => {
      const path = e.url.location.pathname.substring(1)
      if (e.url.action === "POP") {
        if (path !== history.location.pathname.substring(1)){
          Dispatch.nextAction('navigate', path)
        }
        else {
          Dispatch.nextState('url', e.url.location)
          parseSearch(e.url.location.search)
        }
      }
      else {
        let searchObject = {}
        if (!_.isNil(queryParams[path])) {
          Object.keys(queryParams[path])
            .forEach(key => searchObject[key] = queryParams[path][key](e))
        }
        const query = '?' + queryString.stringify(searchObject)
        if (e.url.location.pathname !== history.location.pathname ||
          query !== history.location.search) {
          history.push({
            pathname: '/' + path,
            search: query})
          Dispatch.nextState('url', { pathname: window.location.pathname, search: window.location.search })
        }
      }
  })
}