import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import WebpageWrapper from './WebpageWrapper';

export default (props) => (
  <WebpageWrapper>
  <div style={{ padding: 20 }}>

    <Link to="/" style={{ textDecoration: 'none' }}>
      <FontAwesomeIcon icon="chevron-left" fixedWidth />
      {' '}
      Back
    </Link>

    <p>No job positions are available at this time.</p>

{/*     
    <div className="pt3 job-post">
      <h2 style={{ marginTop: 10 }}>Baristas</h2>

      <p>2894 on Main, a farm-to-fork cafe in East Troy, WI is looking to add to the team! We are searching for hard working individuals to train as Baristas. Weekday and weekend shifts available. Weekends are a must.</p>
      <p>Starting wage depending on experience is $8 to $10 per hour plus tips.</p>
      <p>Part time and full time positions are available.</p>
      <p>Full time positions are offered health insurance, funded HSA and vacation.</p>
      <p>Please send your resume to <a href="mailto:jennifer@2894onmain.com">jennifer@2894onmain.com</a> or call <a href="tel:(262) 924-9920">(262) 924-9920</a>.</p>
    </div> */}

    {/* <div className="pt3 job-post">
      <h2 style={{ marginTop: 10 }}>Server</h2>

      <p>East Troy, WI, community-minded, historic square centered, is an emerging foodie and techie enclave. Summer homeland to Chicago and Milwaukee lake home dwellers, we are also a tourist destination for day-trippers. Located on the north corridor of the village square, 2894 On Main, the community hub cafe, coffee house and general store provides a cafe style breakfast and lunch setting buts also offers full dinner service on Thursday, Friday and Saturday nights.</p>
      <p>2894 is currently seeking a hard-working, motivated individual to fill a part time server position - Thursdays through Saturdays.</p>
      <p>Individual must be 18 years old.</p>
      <p>2894 on Main is an equal opportunity employer.</p>
      <p>Job Type: Part-time</p>
      <p>Salary: $4.00 to $4.50 / hour</p>
      <p>Please send your resume to <a href="mailto:jennifer@2894onmain.com">jennifer@2894onmain.com</a> or call <a href="tel:(262) 924-9920">(262) 924-9920</a>.</p>
    </div> */}
   

    {/*
    <div className="job-post">
      <h2 style={{ marginTop: 10 }}>Executive Chef</h2>

      <p>East Troy, WI (a 30 minute commute from Milwaukee), community-minded, historic square centered, is an emerging foodie and techie enclave. 2894 On Main, is a community hub cafe, coffee house and general store. 2894 serves organic, local and sustainably grown breakfast and lunch in a Cafe style setting and a full dinner service for the evening.</p>

      <p>2894 On Main is currently seeking an executive chef. The food at 2894 is eclectic and heavily influenced by the seasons. We currently serve breakfast and lunch, and have recently expanded to a full dinner service Tuesday through Saturday evenings. We strive to create a memorable experience, while challenging our customer's culinary boundaries. We feature an open kitchen with skylight. 2894 On Main is looking for a highly motivated individual who can oversee the daily kitchen operations, scheduling, ordering as well as head special event services. The executive chef will have creative freedom with menu changes and will be expected to work closely with the owners.</p>

      <p>Requirements:</p>

      <ul>
        <li>3 years of kitchen management experience</li>
        <li>Knowledge of seasonal ingredients</li>
        <li>Ability to work in a fast paced environment</li>
        <li>Strong Communication Skills</li>
        <li>Positive Attitude</li>
      </ul>

      <p>2894 provides competitive pay, health insurance, HSA, paid time off and work-life balance.</p>

      <p>2894 on Main is an equal opportunity employer.</p>
      <p>Please send your resume to <a href="mailto:jennifer@2894onmain.com">jennifer@2894onmain.com</a> or call <a href="tel:(262) 924-9920">(262) 924-9920</a>.</p>
    </div>
    */}

    {/* <div className="job-post">
      <h2>AM Line Cook</h2>
      <p>Responsibilities and Duties</p>
      <p>This list of duties and responsibilities is not all inclusive and may be expanded to include other duties and responsibilities as management may deem necessary from time to time.</p>
      <ul>
        <li>Prepare food for dishes.</li>
        <li>The ability to work in tandem with others as a team during busy service times.</li>
        <li>Keep an ongoing prep list of food needed for service including refrigerated and bread.</li>
        <li>Current knowledge of seasonal produce, basic nutritional information, and list of ingredients in specific menu items.</li>
        <li>Maintain cleanliness of the line and kitchen.</li>
        <li>Kitchen maintenance includes washing dishes, clean up at closing time, and other utility duties of organizing and cleaning as needed.</li>
        <li>Occasionally table service is required - be prepared to talk about dishes with patrons.</li>
        <li>Complete special projects, as assigned</li>
      </ul>
      <p>Benefits: Medical, HSA and Vacation</p>
      <p>Job Type: Full-time</p>
      <p>2894 on Main is an equal opportunity employer.</p>
      <p>Please send your resume to <a href="mailto:jennifer@2894onmain.com">jennifer@2894onmain.com</a> or call <a href="tel:(262) 924-9920">(262) 924-9920</a>.</p>
    </div> */}
   
  </div>
  </WebpageWrapper>
);
