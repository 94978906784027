import React from 'react';

import {
  ImageSlider,
  ContactCard,
  MenuCafe,
  MenuKitchen,
  CustomerSuggestions,
  MailingListSignup
} from '../blocks';
import WebpageWrapper from './WebpageWrapper';

import imgTeslaLogo from '../../media/images/tesla-east-troy.png';

import { Link, withRouter } from 'react-router-dom';

export default (props) => (
  <WebpageWrapper>

    <ImageSlider />

    <div className='w-100 mt1 flex justify-center' style={{backgroundImage: 'linear-gradient(-169deg, #3B5B73 17%, #335472 99%)'}}>
      <div className='w-100 white tc pa3 avenir f4 fw6 bt bt--white'>
        <div className='w-100 h-100'> 
          <Link className='link' to="/shop">
            <div className='dib br2 ph4 pv2 bg-cafegreen pointer' style={{color: "rgb(160, 197, 99)"}}>
              <span className=' link white'>Order Online</span>
            </div>
          </Link>
        </div>
      </div>
    </div>


    <div style={{ marginTop: 20 }}>
      <ContactCard {...props} />
    </div>

    <MenuCafe {...props} />

    {/* <MenuKitchen {...props} /> */}

    <CustomerSuggestions {...props} />

    <MailingListSignup {...props} />

    <div style={{ textAlign: 'center', marginBottom: 10, borderBottom: '1px solid #DAD6D2', }}>
      <img src={imgTeslaLogo} alt="Tesla" style={{ width: 80, marginTop: 30 }} />
      <p style={{ color: '#5F5F5F', fontSize: 13, marginTop: 5, marginBottom: 30 }}>
        Tesla charging located at back of building.
        <br />
        See barista counter for details.
      </p>
    </div>

  </WebpageWrapper>
);
