import { Dispatch } from '@Corim/architecture-frontend'
import { startWith, map, filter, delay, switchMap, tap, takeUntil, skip, share, distinctUntilChanged, publishReplay, refCount, withLatestFrom } from 'rxjs/operators'
import { timer, of, merge, fromEvent, interval, Observable } from "rxjs";
import _ from 'lodash'

Dispatch.getAction('Response.addItem')
  .pipe(
    filter(e => _.isNil(e.errors) && !_.isNil(e.data.addItem))
  )
  .subscribe(e => Dispatch.nextAction('__.Modal.close'))

Dispatch.getAction('Response.pay')
  .pipe(
    filter(e => _.isNil(e.errors) && (!_.isNil(e.data.pay))),
    map(e => e.data.pay.payment),
  )
  .subscribe(e => {
    Dispatch.nextAction('__.Modal.open', { type: 'thankyou', payment: e })
  })

Dispatch.getAction('__.Shop.item.select')
  .subscribe(e => Dispatch.nextAction('__.Modal.open', { type: 'item', target: e }))

Dispatch.getAction('__.Shop.modal.view')
  .pipe(
    filter(e => e.type === 'customerDetails'),
    delay(700)
  )
  .subscribe(e => Dispatch.nextAction('__.Modal.open', {type: 'customerDetails'}))