import items from './data/items'
import mods from './data/modifiers'
import modList from './data/modifierList'
import categories from './data/categories'
import images from './data/images'
import orders from './data/order'
import payment from './data/payment'
import item from './data/item'
import inventory from './data/inventory'
import customer from './data/customer'
import './ui/modal'
import './ui/search'
import './ui/contact'
import './ui/tip'
import fullfillment from './data/fullfillment'
import './ui/tracking'
import config from './data/config'

export default () => {
  items()
  item()
  mods()
  modList()
  categories()
  images()
  fullfillment()
  orders()
  payment()
  inventory()
  customer()
  config()
}