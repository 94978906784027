import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { Dispatch } from '@Corim/architecture-frontend'

export default (props) => {
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  const primaryColor = shop.primaryColor
  const secondaryColor = shop.secondaryColor
  const title = props._title
  const backAction = props._action
  return(
    <div className='pa4'>
      <div className=' flex items-center'>
        <div 
          className={'f2 pr3 fw8 pointer ' + primaryColor}
          onClick={e => backAction()}>
              <FontAwesomeIcon icon={faArrowAltCircleLeft} />
        </div>
        <div className={'f3 fw7 ' + primaryColor}>{title}</div>
      </div>
      <div className={ 'mt3 bt b--moon-gray' }></div>
    </div>
  )
}