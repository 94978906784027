import Storer from './private/Store'
import Dispatcher from './private/Dispatcher'

export default (isProduction, log) => {
  const Store = Storer(isProduction, log)
  const Dispatch = Dispatcher(isProduction, log)
  return {
    nextState: Store.nextState,
    mergeState: Store.mergeState,
    getState: Store.getState,
    nextAction: Dispatch.nextAction,
    getAction: Dispatch.getAction,
    getStateUndefined: Store.getStateUndefined,
  }
}