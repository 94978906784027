import { gql } from '@apollo/client';
import { Dispatch } from '@Corim/architecture-frontend'
import { of, merge, combineLatest } from 'rxjs'
import { map, filter, withLatestFrom, tap, mergeMap, startWith } from 'rxjs/operators'

import _ from 'lodash'

export default () => {
  const CREATE_CUSTOMER_AND_ATTACH_TO_ORDER = gql`
    mutation createCustomerAndAttachToOrder(
      $order: ID!, 
      $version: Int!,
      $given_name: String!, 
      $family_name: String!,
      $email_address: String!,
      $address: JSON, 
      $phone_number: String!,
      $note: String,
      ){
        createCustomerAndAttachToOrder(order: $order, version: $version, given_name: $given_name, family_name: $family_name, email_address: $email_address, address: $address, phone_number: $phone_number, note: $note)
      }
  `;


  if (_.isNil(localStorage.given_name)){
    localStorage.given_name = ''
  }
  Dispatch.nextState('__.Shop.customer.givenName', localStorage.given_name)

  if (_.isNil(localStorage.family_name)){
    localStorage.family_name = ''
  }
  Dispatch.nextState('__.Shop.customer.familyName', localStorage.family_name)

  if (_.isNil(localStorage.email_address)){
    localStorage.email_address = ''
  }
  Dispatch.nextState('__.Shop.customer.emailAddress', localStorage.email_address)

  if (_.isNil(localStorage.phone_number)){
    localStorage.phone_number = ''
  }
  Dispatch.nextState('__.Shop.customer.phoneNumber', localStorage.phone_number)

  if (_.isNil(localStorage.rememberMe)){
    localStorage.rememberMe = false
  }
  Dispatch.nextState('__.Shop.customer.rememberMe', JSON.parse(localStorage.rememberMe))

  Dispatch.getAction('customerDetails')
      .pipe(
        withLatestFrom(
          Dispatch.getState('Textbox.payment.family_name'), 
          Dispatch.getState('Textbox.payment.given_name'), 
          Dispatch.getState('Textbox.payment.email_address'), 
          Dispatch.getState('Textbox.payment.phone_number'),
          Dispatch.getState('__.Shop.order'),
          Dispatch.getState('__.Shop.customer.rememberMe'),
        ),
        //filter(e => e === 'payment')
      )
      .subscribe(([,family_name, given_name, email_address, phone_number, order, rememberMe ]) => {
        if (rememberMe) {
          localStorage.family_name = family_name
          localStorage.given_name = given_name
          localStorage.email_address = email_address
          localStorage.phone_number = phone_number
          localStorage.rememberMe = true
        }
        else {
          localStorage.family_name = ''
          localStorage.given_name = ''
          localStorage.email_address = ''
          localStorage.phone_number = ''
          localStorage.rememberMe = false
        }
        Dispatch.nextAction('Request.createCustomerAndAttachToOrder', { 
          type: 'mutation', 
          query: CREATE_CUSTOMER_AND_ATTACH_TO_ORDER, 
          variables: {
            order: order.id,
            version: order.version,
            family_name,
            given_name,
            email_address,
            phone_number,
          }
        })
      })
}

  Dispatch.getAction('Response.createCustomerAndAttachToOrder')
    .pipe(
      filter(e => _.isNil(e.errors)),
      map(e => e.data?.createCustomerAndAttachToOrder),
    )
    .subscribe(e => {
      Dispatch.nextState('__.Shop.customer', e.customer),
      Dispatch.nextAction('__.Modal.open', {type: 'payment'})
    })