import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils } from '@fortawesome/free-solid-svg-icons'
import { Dispatch, MultiSelect, Textbox, SubmitButton } from '@Corim/architecture-frontend'
import { of, merge, combineLatest } from 'rxjs'
import { map, filter, withLatestFrom, tap, mergeMap, startWith } from 'rxjs/operators'
import ModalNavigator from './ModalNavigator';
import loading from '../../media/loading.svg'

export default (props) => {
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  const primaryColor = shop.primaryColor
  const secondaryColor = shop.secondaryColor
  
  return(
    <div className='w-100'>
      <ModalNavigator {...props} _title='Details' _action={e => Dispatch.nextAction('__.Modal.open', { type: 'fullfillment' })} />
      <div className='pa4'>
            <div className='w-100 ph4'>
                  <Textbox
                      className='w-100 pv3 ph3 ba br2 dark-gray b--moon-gray fw4 '
                      id='payment.given_name'
                      placeholder='First Name'
                      style={{ outline: 'none', borderStyle: 'solid' }}
                      type='text'
                      defaultValue={shop?.customer?.givenName ?? ''}
                  />
                  <Textbox
                      className='w-100 mt2 pv3 ph3 ba br2 dark-gray b--moon-gray fw4 '
                      id='payment.family_name'
                      placeholder='Last Name'
                      style={{ outline: 'none', borderStyle: 'solid' }}
                      type='text'
                      defaultValue={shop?.customer?.familyName ?? ''}
                  />
                  <Textbox
                      className='w-100 mt2 pv3 ph3 ba br2 dark-gray b--moon-gray fw4 '
                      id='payment.email_address'
                      placeholder='Email'
                      style={{ outline: 'none', borderStyle: 'solid' }}
                      type='text'
                      defaultValue={shop?.customer?.emailAddress ?? ''}
                  />
                  <Textbox
                      className='w-100 mt2 pv3 ph3 ba br2 dark-gray b--moon-gray fw4 '
                      id='payment.phone_number'
                      placeholder='Phone'
                      style={{ outline: 'none', borderStyle: 'solid' }}
                      type='text'
                      defaultValue={shop?.customer?.phoneNumber ?? ''}
                  />
                  <div className='w-100 pt3 flex justify-between'>
                    <div></div>
                    <div class='flex'>
                      <div className='f6 fw7'>Remember me?</div>
                      <div className='pl2'><input onChange={e => Dispatch.nextState('__.Shop.customer.rememberMe', e.target.checked)} type="checkbox" id="vehicle1" name="vehicle1" value="Bike" checked={shop?.customer?.rememberMe} onCl/></div>
                    </div>
                  </div>
                </div>
        <div className='pt4 w-100 flex justify-center'>

          <SubmitButton id={'pay'} className='pt2' 
                defaultChild={ props => (
                  <div 
                    className={'mt2 ph5 pv3 br2 f4 fw5 pointer bg-' + primaryColor + ' ' + secondaryColor} 
                    onClick={e => {
                      Dispatch.nextAction('customerDetails', '')
                      
                    }}>
                      Payment
                  </div>
                )}
                loadingChild={ props => (
                  <div key='loading' className='w-100 ph4 flex justify-center'>
                    <div className='w2'>
                      <img src={loading} />
                    </div>
                  </div>
                )}
                errorChild={ props => {
                  return (
                  <div key='error' className='pl3 f5 fw7 flex justify-center red'> 
                    <div>Error: {props.errors?.[0]?.message}</div>
                  </div>
                )}}
                loadingStream={
                  Dispatch.getAction('customerDetails')
                  }
                successStream={
                  Dispatch.getAction('Response.createCustomerAndAttachToOrder')
                    .pipe(
                      filter(e => _.isNil(e.errors) && (!_.isNil(e.data.createCustomerAndAttachToOrder))),
                    )
                  }
                errorStream={
                  Dispatch.getAction('Response.createCustomerAndAttachToOrder')
                    .pipe(
                      filter(e => !_.isNil(e.errors) || (_.isNil(e.data.createCustomerAndAttachToOrder))),
                    )}
                  />
        </div>
      </div>
      <div className='h4 h1-ns'></div>
    </div>
  )
}