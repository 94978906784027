import { gql } from '@apollo/client';
import { Dispatch } from '@Corim/architecture-frontend'
import { map, filter } from 'rxjs/operators'

import _ from 'lodash'

export default () => {
  const GET_ITEMS = gql`
  query modifiers {
    modifiers 
  }
  `;

  Dispatch.nextAction('Request.modifiers', { type: 'query', query: GET_ITEMS, variables: { }, poll: process.env.REACT_APP_POLL_INTERVAL, retry: process.env.REACT_APP_RETRY_INTERVAL })

  Dispatch.getAction('Response.modifiers')
    .pipe(
      filter(e => _.isNil(e.errors)),
      map(e => e.data.modifiers),
      map(e => e.objects)
    )
    .subscribe(e => Dispatch.nextState('__.Shop.modifiers', e))
}