
// import { HttpLink } from 'apollo-boost';

// import { ApolloClient } from 'apollo-boost'
// //import { HttpLink } from 'apollo-boost'
// import { InMemoryCache } from 'apollo-boost'
// import { ApolloLink } from 'apollo-boost';
// import { setContext } from 'apollo-link-context';
// // import { SchemaLink } from 'apollo-link-schema';

export default (Auth, ApolloClient, HttpLink, InMemoryCache, ApolloLink, setContext, createUploadLink, fragmentMatcher=null) => {

  // const httpLink = new HttpLink({
  //   uri: (process.env.NODE_ENV === 'production') ? process.env.REACT_APP_PROD_API : process.env.REACT_APP_DEV_API
  // })

  const fileLink = createUploadLink({
    uri: (process.env.NODE_ENV === 'production') ? process.env.REACT_APP_PROD_API : process.env.REACT_APP_DEV_API
  })

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  };

  // const authLink = setContext((_, { headers }) => {
  //   // get the authentication token from local storage if it exists
  //   const token = localStorage.getItem('access_token');
  //   // return the headers to the context so httpLink can read them
  //   return {
  //     headers: {
  //       ...headers,
  //       authorization: token ? `Bearer ${token}` : "",
  //     }
  //   }
  // });
  let authLink = null

  if (Auth) {
    authLink = setContext((_, { headers }) => 
      new Promise((success, fail) => {
        Auth.currentSession()
          .then(e => success({
            headers: {
              ...headers,
              authorization: e.accessToken ? `Bearer ${e.accessToken.jwtToken}` : "",
            }
          }))
          .catch(e => success({
            headers: {
              ...headers,
              authorization: e.accessToken ? `Bearer ${e.accessToken.jwtToken}` : "",
            }
          }))
      })
    );
  }

  const cacheOptions = fragmentMatcher ? { fragmentMatcher } : {}

  const link = authLink ?
    ApolloLink.from([
      authLink,
      //httpLink,
      fileLink
    ])
    :
    ApolloLink.from([
      fileLink
    ])

  return new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
    // to a different host
    link,
    cache: new InMemoryCache(cacheOptions),
    defaultOptions
  });
}
