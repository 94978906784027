import { Dispatch } from '@Corim/architecture-frontend'
import { combineLatest } from 'rxjs'
import { map, distinctUntilChanged, withLatestFrom, filter, startWith, scan, tap, take } from 'rxjs/operators'

import _ from 'lodash'

Dispatch.getState('__.Shop.defaultTip')
  .pipe(
    take(1)
  )
  .subscribe(e => Dispatch.nextState('__.Shop.tip.percentage', e))

Dispatch.getState('MultiSelect.tip')
  .pipe(
    map(e => Object.keys(e)),
    filter(e => e.length > 0),
    map(e => e[0]),
  )
  .subscribe(e => Dispatch.nextState('__.Shop.tip.percentage', e))

  Dispatch.getAction('MultiSelect.click.tip')
  .subscribe(e => Dispatch.nextState('__.Shop.tip.open', false))

Dispatch.getAction('__.Shop.changeTipPercentage')
    .pipe(
      withLatestFrom(Dispatch.getState('__.Shop.tip.open')),
      map(([, open]) => !open),
      startWith(false)
    )
    .subscribe(e => Dispatch.nextState('__.Shop.tip.open', e))