import { gql } from '@apollo/client';
import { Dispatch } from '@Corim/architecture-frontend'
import { combineLatest, merge } from 'rxjs'
import { map, distinctUntilChanged, startWith,delay, debounceTime, filter } from 'rxjs/operators'

import _ from 'lodash'

function multiIncludes(text, values){
  var re = new RegExp(values.join('|'));
  return re.test(text);
}

combineLatest(Dispatch.getState('__.Shop.items'), Dispatch.getState('__.Shop.categories'), Dispatch.getState('Textbox.search'), Dispatch.getState('MultiSelect.category').pipe(startWith({All: true})), Dispatch.getState('__.Shop.categorySearch'))
  .pipe(
    debounceTime(800),
    map(([items, categories, search, selectedCategory, defaultSearchCategories]) => {
      if (items === 'loading') {
        return 'loading'
      }
      else {
        return _.filter(items, item => {
          const selectedCategoryString = Object.keys(selectedCategory)?.[0]
          const selectedCategoryTags = _.find(defaultSearchCategories, category => category.name === selectedCategoryString)?.tags
          const category = _.find(categories, category => category.id === item.item_data.category_id)
          const inCategory = category && category.category_data.name.toLowerCase().includes(search.toLowerCase())
          const inSelectedCategory = multiIncludes(item.custom_attribute_values?.tags?.string_value?.toLowerCase(), selectedCategoryTags) ?? selectedCategoryString?.toLowerCase() === 'all' ? true : false
          return (item.item_data.name.toLowerCase().includes(search.toLowerCase()) || (item.item_data.description && item.item_data.description.toLowerCase().includes(search.toLowerCase()) || inCategory)) && inSelectedCategory
        })
      }
    })
  )
  .subscribe(e => {
    Dispatch.nextState('__.Shop.filteredItems', e)
  })

  // merge(Dispatch.getState('Textbox.search'), Dispatch.getState('MultiSelect.category').pipe(startWith({All: true})))
  //   .subscribe(e => Dispatch.nextState('__.Shop.loading.items', true))

  // Dispatch.getState('__.Shop.filteredItems')
  //   .subscribe(e => Dispatch.nextState('__.Shop.loading.items', false))
    
  // Dispatch.getState('__.Shop.filteredItems')
  //   .pipe(
  //     delay(100)
  //   )
  //   .subscribe(e =>
  //     document.getElementById('itemList').scrollIntoView()
  //   )