
import React from 'react';
import ItemBrowser from './ItemBrowser'
import MobileCart from './MobileCart'
import loading from '../../media/loading.svg'
import ScriptTag from 'react-script-tag';


export default (props) => {
  const StoreHeader = props.__ && props.__.Shop && props.__.Shop.StoreHeader
  return (
    <div>
      {(process.env.NODE_ENV === 'production' ? <ScriptTag type="text/javascript" src="https://web.squarecdn.com/v1/square.js"/> : <ScriptTag type="text/javascript" src="https://sandbox.web.squarecdn.com/v1/square.js"/>)}
      <div></div>
      <MobileCart {...props} />
      <StoreHeader {...props} />
      <ItemBrowser {...props} />
      <div className='dn'><img src={loading}/></div>
    </div>
  )
}