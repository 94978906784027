import React from 'react';
import { withRouter } from 'react-router-dom';

import WebpageWrapper from './WebpageWrapper';

const Error404 = ({ location }) => (
  <WebpageWrapper>
    <h3>
      Error 404. No match for <code>{location.pathname}</code>
    </h3>
  </WebpageWrapper>
)

export default withRouter(Error404)
