import { gql } from '@apollo/client';
import { Dispatch } from '@Corim/architecture-frontend'
import _ from 'lodash'
import {merge} from 'rxjs'
import { withLatestFrom, filter, tap } from 'rxjs/operators'
import moment from 'moment'

export default () => {
  const PAY = gql`
  mutation pay(
    $order: ID!
    $version: Int!
    $amount: Int!
    $tip_amount: Int!
    $nonce: String!
    $token: String!
    $method: String!
    $paymentType: String!
    $scheduledTime: String!
    ){
      pay(order: $order, version: $version, amount: $amount, tip_amount: $tip_amount, nonce: $nonce, token: $token, method: $method, paymentType: $paymentType, scheduledTime: $scheduledTime)
    }
  `;

merge(Dispatch.getAction('cart.pay'), Dispatch.getAction('cart.payGoogle'), Dispatch.getAction('cart.payApple'), Dispatch.getAction('cart.payGift'))
    .pipe(
      withLatestFrom(
        Dispatch.getState('__.Shop.order'),
        Dispatch.getState('__.Shop.fulfillment.bookings')
      ),
    )
    .subscribe(([square, order, scheduledTime]) => Dispatch.nextAction('Request.pay', { 
      type: 'mutation', 
      query: PAY, 
      variables: {
        order: order.id,
        version: order.version,
        amount: order?.pricing?.amountRemaining,
        tip_amount: order?.pricing?.tipRemaining,
        nonce: square.nonce,
        token: square.token,
        paymentType: square.paymentType ?? '',
        method: 'PICKUP', //order?.fullfillment?.method,
        scheduledTime: moment(scheduledTime).toISOString() ?? ''
        //schedule: order?.fullfillment?.schedule,
        //bookingHash: 
      }
    }))
}

// Remove error message for payments
Dispatch.getAction('Response.pay')
  .pipe(
    filter(e => _.isNil(e.errors)),
  )
.subscribe(e => Dispatch.nextState('__.Shop.payment', {payment_error: null, ...e.data.pay}))

// Set an error message for payments
Dispatch.getAction('Response.pay')
  .pipe(
    filter(e => !_.isNil(e.errors))
  )
.subscribe(e => Dispatch.nextState('__.Shop.payment.payment_error', e.errors[0].message))