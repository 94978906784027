
import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Dispatch, Request } from '@Corim/architecture-frontend'
import { StoreEngine } from '@Corim/squareshop'
import './clock.css'

import ReactPixel from 'react-facebook-pixel';

import './media';
import Apollo from './apollo'

import App from './components/App';

const options = {
	autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
ReactPixel.init('297893567907339', options);

ReactPixel.pageView();

Request(Dispatch, Apollo)
StoreEngine()

const AppWrapper = () => (
    <Router>
      <App />
    </Router>
);

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(<AppWrapper />, rootElement);
} else {
  render(<AppWrapper />, rootElement);
}
