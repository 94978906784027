import { gql } from '@apollo/client';
import { Dispatch } from '@Corim/architecture-frontend'

import {  combineLatest } from "rxjs";
import {  map, filter, withLatestFrom, tap } from 'rxjs/operators'

import _ from 'lodash'

export default () => {
  const GET_CATEGORIES = gql`
    query categories {
      categories
    }
  `;

  const GET_VENDORS = gql`
    query vendors {
      vendors {
        id
        squareId
        description
        logo
        website
        priority
        subDescription
      }
    }
  `;

  Dispatch.nextAction('Request.categories', { type: 'query', query: GET_CATEGORIES, variables: { }, poll: process.env.REACT_APP_POLL_INTERVAL,  retry: process.env.REACT_APP_RETRY_INTERVAL })

  Dispatch.nextAction('Request.vendors', { type: 'query', query: GET_VENDORS, variables: { }, poll: process.env.REACT_APP_POLL_INTERVAL,  retry: process.env.REACT_APP_RETRY_INTERVAL })

combineLatest(
  combineLatest(
    Dispatch.getAction('Response.categories')
      .pipe(
        filter(e => _.isNil(e.errors)),
        map(e => e.data.categories),
        map(e => e.objects)
      ), 
    Dispatch.getAction('Response.vendors')
      .pipe(
        filter(e => _.isNil(e.errors)),
        map(e => e.data.vendors)
      )
    )
    .pipe(
      map(([categories, vendors]) => {
        const result = categories.map(category => ({ ...category, vendor_data: _.find(vendors, vendor => vendor.squareId === category.id) }))
        return _.orderBy(result, category => {
          return (category.vendor_data ? category.vendor_data.priority : 10000)
        })
      }),
      
    ),
    Dispatch.getState('__.Shop.items')
    )
    .pipe(  
      map(([categories, items]) => {
        return categories.reduce((acc,next) => {
          const filteredItems = _.filter(items, item => item.item_data.category_id === next.id)
          return filteredItems.length > 0 ? [...acc, next] : acc
        }, [])
      })
    )
    .subscribe(e => Dispatch.nextState('__.Shop.categories', e))
}