import React from 'react'

const MailingListSignup = () => (
  <div
    style={{
      padding: '25px 20px',
      backgroundImage: 'linear-gradient(-169deg, #3B5B73 17%, #335472 99%)',
      boxShadow: '0 2px 26px 0 rgba(0,0,0,0.22)',
      textAlign: 'center',
    }}
  >

    <h3 style={{ marginTop: 0, marginBottom: 0, color: '#fff', fontWeight: 200 }}>
      Get on the list. Stay up-to-date
    </h3>

    <p style={{ marginTop: 5, color: '#A9B7C2', fontSize: 14 }}>
      Subscribe to our mailing list and be the first to know of upcoming events and specials.
    </p>

    <form
      action="https://2894onmain.us17.list-manage.com/subscribe/post?u=8efdbb544f1a7aaed418d39fe&amp;id=189f544ba3"
      method="POST"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      rel="noopener noreferrer"
      style={{ marginTop: 20 }}
    >
      <input
        type="email"
        name="EMAIL"
        id="mce-EMAIL"
        placeholder="Your Email Address"
        style={{
          width: '90%',
          maxWidth: 300,
          fontSize: 14,
          padding: '7px 10px',
          borderRadius: 5,
          border: '1px solid #8C8C8C',
          boxShadow: 'inset 1px 1px 2px 0 rgba(0,0,0,0.19)',
          fontFamily: '"Open sans", Helvetica, sans-serif'
        }}
      />
      <br />
      <button
        type="submit"
        name="subscribe"
        id="mc-embedded-subscribe"
        className="button"
        style={{
          marginTop: 20,
          padding: '10px 25px',
          fontWeight: 'bold',
          background: '#A6CF72',
          border: '1px solid rgba(7,7,7,0.06)',
          boxShadow: '0 2px 10px 0 rgba(0,0,0,0.09)',
          textShadow: '0 1px 0 rgba(0,0,0,0.5)',
          fontFamily: '"Open sans", Helvetica, sans-serif',
          borderRadius: '4px',
          color: '#fff',
          cursor: 'pointer',
          fontSize: 16
        }}
      >
        Subscribe
      </button>

      <div id="mce-responses" className="clear">
        <div className="response" id="mce-error-response" style={{ display: 'none' }} />
        <div className="response" id="mce-success-response" style={{ display: 'none' }} />
      </div>

      <div style={{ position: 'absolute', left: '-5000px', display: 'none' }}>
        <input type="text" name="b_8efdbb544f1a7aaed418d39fe_189f544ba3" tabIndex="-1" />
      </div>

    </form>

  </div>
)

export default MailingListSignup
