import { gql } from '@apollo/client';
import { Dispatch } from '@Corim/architecture-frontend'
import { of, merge, from, timer, combineLatest} from 'rxjs'
import { map, filter, withLatestFrom, tap, mergeMap, take, distinctUntilChanged, debounceTime, switchMap, delay, startWith} from 'rxjs/operators'
import moment from 'moment'
import _ from 'lodash'

export default () => {

const GET_NEXT_AVAILABLE_ORDER_TIME = gql`
query getNextAvailableOrderTime($order: String! $grouped: Boolean! $targetFinishTime: String) {
  getNextAvailableOrderTime(order: $order grouped: $grouped, targetFinishTime: $targetFinishTime)
}
`;

Dispatch.getState('__.Shop.order.line_items')
    .pipe(
        distinctUntilChanged((a, b) => _.isEqual(a, b)),
        mergeMap(() => combineLatest(
              Dispatch.getState('modal.type')
                .pipe(
                  filter(e => e === 'fullfillment'), 
                  startWith(null)
                ),    
              Dispatch.getState('__.Shop.order.fullfillment')
            )
            .pipe(
                map(e => e[1]),
                withLatestFrom(Dispatch.getState('__.Shop.order')),
                map(([fullfillment, order]) => ({fullfillment, order: order })),
            )
        )
    )   
    .subscribe(e => {
      Dispatch.nextAction('Request.nextAvailableOrderTime', { type: 'query', query: GET_NEXT_AVAILABLE_ORDER_TIME, variables: { order: e.order.id, grouped: true, targetFinishTime: e.fullfillment.time }, })
})

Dispatch.getAction('Request.nextAvailableOrderTime')
  .subscribe(e => Dispatch.nextState('__.Shop.fulfillment.bookings', 'loading'))

Dispatch.getAction('Response.nextAvailableOrderTime')
    .pipe(
      filter(e => _.isNil(e.errors)),
      map(e => e.data.getNextAvailableOrderTime),
      switchMap(e => timer(0, 60000)
        .pipe(
          map(minutes => moment(e).add(minutes, 'minutes'))
        )
      )
      // Don't update while polling unless something has changed
      //distinctUntilChanged((a, b) => _.isEqual(a, b)),
    )
    .subscribe(e => Dispatch.nextState('__.Shop.fulfillment.bookings', e))

  Dispatch.getAction('Response.pay')
    .pipe(
      filter(e => _.isNil(e.errors)),
    )
    .subscribe(e => Dispatch.nextState('__.Shop.fulfillment', {bookedBookings: null, bookings: null}))

}