import { gql } from '@apollo/client';
import { Dispatch } from '@Corim/architecture-frontend'
import { map, filter } from 'rxjs/operators'

import _ from 'lodash'

export default () => {
  const GET_IMAGES = gql`
  query images {
    images
  }
  `;

  Dispatch.nextAction('Request.images', { type: 'query', query: GET_IMAGES, variables: { }, poll: process.env.REACT_APP_POLL_INTERVAL, retry: process.env.REACT_APP_RETRY_INTERVAL})

  Dispatch.getAction('Response.images')
    .pipe(
      filter(e => _.isNil(e.errors)),
      map(e => e.data.images),
      map(e => e.objects)
    )
    .subscribe(e => Dispatch.nextState('__.Shop.images', e))
}