import React from 'react'

const loadingIcon = require('../../media/images/loading.svg')

const PendingStateButton = props => {
  const { targetState } = props
  let ToRender = NormalState
  if (targetState === 'pending') {
      ToRender = PendingState
  } else if (targetState === 'success') {
    // todo
  } else if (targetState === 'error') {
      ToRender = ErrorState
  }
   return (
     <ToRender {...props} />
   )
}

const NormalState = ({css, handleSubmit, text}) => (
  <div
    className={`bg-blue white tc center ${css}`}
    onClick={e => handleSubmit()}
  >
    {text}
  </div>
)

const ErrorState = ({ css, handleSubmit }) => (
  <div
    className={`tc center white bg-red ${css}`}
    onClick={e => handleSubmit()}
  >
    Error
  </div>
)

const PendingState = () => (
  <div className='pv3 w3 center'>
    <img className='w-100' src={loadingIcon} alt="Logo" />
  </div>
)

export default PendingStateButton
